import { useState, useEffect } from "react";
import axios from 'axios'

const useFilterHook = items => {
  const [catalogList, setCatalogList] = useState([]);
  const [currentCards, setCurrentCards] = useState([]);
  const [selectedInputSelect, setSelectedInputSelect] = useState({ value: "По содержанию", id: 0 });
  const [search, setSearch] = useState("");
  const [selectedCategoryTab, setCategoryTab] = useState({ id: 0, value: "Все категории" });
  const [selectedSubjectTab, setSubjectTab] = useState({ id: 0, value: "Все предметы" });
  const [selectedSortingTab, setSortingTab] = useState({ value: "по алфавиту", id: 1 });
  const [isActiveSorting, setIsActiveSorting] = useState(false);
  const [pageChange, setNewCurrentCards] = useState(1);
  const [currentCardsPagination, setCurrentCardsPagination] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/programs`,);
      setCatalogList(result.data);
    };

    fetchData();
  }, []);

  const handleInputSelect = newValue => {
    setSelectedInputSelect(newValue);
  };

  const handleInputSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let currentCards = getCurrentCards(
      catalogList,
      selectedCategoryTab,
      selectedSubjectTab
    ).filter(searchFilter);
    setCurrentCards(currentCards)
    newCurrentCards(1);
  }, [search, catalogList, selectedCategoryTab, selectedSubjectTab, selectedSortingTab]);

  useEffect(() => {
    setCategoryTab({ id: 0, value: "Все категории" })
    setSubjectTab({ id: 0, value: "Все предметы" })
    setSearch("")
  }, [selectedInputSelect])

  const searchFilter = item => {
    return item.text.toLowerCase().search(search.toLowerCase()) !== -1;
  };

  const handleCategoryTabChange = newValue => {
    setCategoryTab(newValue);
  };

  const handleSubjectTabChange = newValue => {
    setSubjectTab(newValue);
  };

  const handleSortingTab = newValue => {
    setIsActiveSorting(true);
    setSortingTab(newValue);
  };

  const SortingAsc = () => {
    let sortingTabId = selectedSortingTab.id;
    const copyData = [...currentCards];
    const collator = new Intl.Collator();

    let sortedData = copyData.sort(function sortingData(a, b) {
      if (isActiveSorting) {
        switch (sortingTabId) {
          case 2:
            return collator.compare(a.location, b.location);
          default:
            return collator.compare(a.title, b.title);
        }
      } else {
        switch (sortingTabId) {
          case 2:
            return collator.compare(b.location, a.location);
          default:
            return collator.compare(b.title, a.title);
        }
      }
    });

    toggleSorting();

    setCurrentCards(sortedData);
  };

  const toggleSorting = () => {
    setIsActiveSorting(!isActiveSorting);
  };

  const getCurrentCards = (
    catalogList,
    selectedCategoryTab,
    selectedSubjectTab
  ) => {
    let currentCards;

    if ((selectedCategoryTab.id === undefined || selectedCategoryTab.id === 0) && (selectedSubjectTab.id === undefined || selectedSubjectTab.id === 0)) {
      return catalogList;
    } else if (selectedCategoryTab.id === undefined || selectedCategoryTab.id === 0) {
      currentCards = catalogList.filter(
        catalogItem => 
        catalogItem.subject_id === selectedSubjectTab.id
      );
    } else if (selectedSubjectTab.id === undefined || selectedSubjectTab.id === 0) {
      currentCards = catalogList.filter(
        catalogItem => 
        catalogItem.category_id === selectedCategoryTab.id
      );
    } else {
      currentCards = catalogList.filter(
        catalogItem =>
          catalogItem.category_id === selectedCategoryTab.id &&
          catalogItem.subject_id === selectedSubjectTab.id
      );
    }
    return currentCards;
  };

  const onPageChanged = currentPage => {
    newCurrentCards(currentPage);
  };

  const newCurrentCards = (currentPage) => {
    const pageLimit = 6;
    const offset = (currentPage - 1) * pageLimit;
    const currentCardsPagination = currentCards.slice(offset, offset + pageLimit);
    const totalPages = Math.ceil(currentCards.length / pageLimit);
    setCurrentPage(currentPage);
    setCurrentCardsPagination(currentCardsPagination)
    setTotalPages(totalPages)
  };

  return {
    catalogList,
    currentCards,
    selectedInputSelect,
    handleInputSelect,
    search,
    handleInputSearch,
    selectedCategoryTab,
    handleCategoryTabChange,
    selectedSubjectTab,
    handleSubjectTabChange,
    handleSortingTab,
    SortingAsc,
    selectedSortingTab,
    onPageChanged,
    currentCardsPagination
  };
};

export default useFilterHook;
