import React from 'react';
import './Card.scss';
import Parser from 'html-react-parser';

export const Card = ({ item }) => {
  return (
    <div className="Card" onClick={() => (window.location = item.linkShow)}>
      <div className="Card__image-container">
        <img
          className="Card__image"
          src={
            item.picture
              ? `${process.env.REACT_APP_API_URL}/uploads/${item.picture?.dir}/${item.picture?.filename}`
              : `/img/news/no_image.png`
          }
          alt="card__image"
        />
      </div>
      <div className="Card__block-container">
        <p className="Card__title">{item.title}</p>
      </div>
      <div className="Card__block-container">
        <p className="Card__content">{Parser(item.content)}</p>
      </div>
      <div className="Card__block-container">
        <p className="Card__data">{item.published_at}</p>
      </div>
    </div>
  );
};
