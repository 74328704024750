import React, { useState } from 'react'
import { Form, Radio, Input } from 'semantic-ui-react'

const RadioGroup =(props)=> {
    const [value, setStateValue] = useState('');
    const [input_value, setInputValue] = useState('');

    function setValue(value, custom_value=false){
        console.log(custom_value);
        if(custom_value) setInputValue(custom_value);
        setStateValue(value);
        props.setValue(custom_value!==false ? custom_value : value);
    }
    return (
        <Form>
            {props.custom_first!==undefined &&
                <div>
                    <Radio onChange={(e, { value })=>setValue('other',input_value)} label={props.custom_first} checked={value === 'other'} />
                    <Input disabled={value !== 'other'} onChange={(e)=>setValue('other',e.target.value)} />
                </div> 
            }
            {props.fields.map((name,index)=>{
                return (
                    <Form.Field key={name+index}>
                        <Radio
                        label={name}
                        name='radioGroup'
                        value={name}
                        checked={value === name}
                        onChange={(e, { value })=>setValue(value)}
                        />                        
                    </Form.Field>
                )
            })}
            {props.custom_last!==undefined &&
                <div>
                    <Radio onChange={(e, { value })=>setValue('other',input_value)} label={props.custom_last} checked={value === 'other'} />
                    <Input style={{marginLeft:'15px'}} disabled={value !== 'other'} onChange={(e)=>setValue('other',e.target.value)} />
                </div> 
            }
        </Form>
    )
  
}
export default RadioGroup