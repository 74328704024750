import React from 'react';
import ReactDOM from 'react-dom';

import './Modal.scss';

const Modal = ({ isShowing, hide, children, ...props }) => {
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div
            className="modal-overlay modal-wrapper-single"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            onClick={props.toggleBack}
          />
          {children}
        </>,
        document.body,
      )
    : null;
};

export default Modal;
