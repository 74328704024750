import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import logo from '../../assets/images/png/logo.png';
import FullProgrammPage from '../RegistryPage/FullProgrammPage/FullProgrammPage';
import RegistryList from '../RegistryPage/RegistryList';
import './RegistryPage.scss';

const RegistryPage = () => {
  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/registry/" exact component={RegistryList} />
        <Route path="/registry/detail/:id" component={FullProgrammPage} />
        <Redirect to="/404" />
      </Switch>
    </>
  );
};

const RegistryCollapsePageCard = () => {
  return (
    <>
      <div className="RegistryPageCard">
        <div className="RegistryPageCard__left-container">
          <div className="RegistryPageCard__category">
            Естественнонаучное образование
          </div>
          <div className="RegistryPageCard__sub-category">Астрономия</div>
          <div className="RegistryPageCard__title">
            Современные подходы к работе с детьми раннего возраста в дошкольной
            образовательной организации
          </div>
        </div>
        <div className="RegistryPageCard__right-container">
          <div className="RegistryPageCard__programm">
            <span>Программы повышения квалификации</span>
          </div>
          <div className="RegistryPageCard__interactive">
            <span className="RegistryPageCard__view-btn">смотреть</span>
            <span className="RegistryPageCard__download-btn">скачать</span>
          </div>
        </div>
        <div className="Collapse-btn">Показать курсы</div>
      </div>
      <div className="Collapse-container">
        <CollapseBlockCard />
        <CollapseBlockCard />
      </div>
    </>
  );
};

const CollapseBlockCard = () => {
  return (
    <div className="CollapseBlockCard">
      <div className="CollapseBlockCard__title">
        Разработка и подбор тестовых заданий различного уровня сложности
      </div>
      <div className="CollapseBlockCard__location">
        ГАУ ДПО «Саратовский областной институт развития образования»
      </div>
      <div className="CollapseBlockCard__desc">
        Очная форма обучения / 70 часов
      </div>
      <button className="CollapseBlockCard__btn">Перейти</button>
    </div>
  );
};

export default RegistryPage;

const items = [
  {
    title:
      'проектирование и разработка специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'z индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 1,
    category_id: 1,
    subject_id: 4,
    input_id: 21,
  },
  {
    title:
      'разработка специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'f профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Астраханская область',
    subject: 'Математика',
    id: 21,
    category_id: 2,
    subject_id: 5,
    input_id: 2,
  },
  {
    title:
      'специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'l для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы',
    location: 'Хабаровская область',
    subject: 'Математика',
    id: 43,
    category_id: 1,
    subject_id: 5,
    input_id: 43,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 2,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 26,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 82,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 29,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 20,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 34,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'Последняя разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 99,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
];

const inputSelect = [
  { value: 'По содержанию', id: 0 },
  { value: 'Гуманитарное образование Естественнонаучное образование', id: 1 },
  { value: 'Математика', id: 21 },
  { value: 'Программирование и ИКТ', id: 43 },
  { value: 'Начальное образование', id: 2 },
];

const regions = [
  { value: 'United States', id: 1 },
  { value: 'Great Britian', id: 21 },
  { value: 'Mexico', id: 43 },
  { value: 'Canada', id: 2 },
];

const sortItems = [
  { value: 'по алфавиту', id: 1 },
  { value: 'по расположению', id: 21 },
  { value: 'по автору', id: 43 },
  { value: 'по предмету', id: 2 },
];

const subjects = [{ value: 'Астрономия', id: 1 }];

const categoryItems = [
  { id: 0, value: 'Все категории' },
  { id: 1, value: 'Повышение квалификации' },
  { id: 2, value: 'Переподготовка' },
];
