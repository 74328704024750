import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import spinner from '../../assets/images/loader.gif';
import extentionChecker from '../../helpers/extentionChecker';
import useFilterHook from '../../helpers/SortingRegistryHook';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import './BankPage.scss';

const BankPageList = (props) => {
  const {
    year,
    setYear,
    regions,
    subjects,
    educationForm,
    selectedEducationForm,
    categories,
    handleEducationFormChange,
    handleInputOrganisation,
    organisationSearch,
    sortTab,
    searchAuthor,
    handleInputSearch,
    selectedCategoryTab,
    handleCategoryTabChange,
    handleSortingTab,
    selectedSortingTab,

    selectedRegion,
    handleRegion,
    selectedSubject,
    handleSubject,
    selectedYear,
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    handleYear,
    queryParams,
    clearBtn,
    handleFullSearch,
    fullSearch,

    courseOfTrainingFrom,
    handleCourseOfTrainingFrom,
    courseOfTrainingTo,
    handleCourseOfTrainingTo,
  } = useFilterHook(props);
  const [infoData, setInfoData] = useState(null);
  const [filterURL, setFilterURL] = useState(-1);
  const [loadingCards, setLoadingCards] = useState(true);
  const [isError, setIsError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const submitFilter = useCallback(
    (params) => {
      let query = Object.keys(params)
        .map(
          (key) =>
            encodeURIComponent(key) + '=' + encodeURIComponent(params[key]),
        )
        .join('&');
      let url = `${process.env.REACT_APP_API_URL}/api/v1/programs?` + query;
      setFilterURL(query);

      const fetchData = async () => {
        setIsError(false);
        setLoadingCards(true);
        try {
          const result = await axios(url);
          setTotalPages(result.data.pagination.total_pages);
          setInfoData(result.data.items);
        } catch (error) {
          setIsError(true);
        }
        setLoadingCards(false);
      };

      fetchData();

      props.history.push({
        search: `?${query}`,
      });
    },
    [props.history, setFilterURL, setIsError, setLoadingCards],
  );

  useEffect(() => {
    let link = queryString.parse(props.location.search);

    const startPageCount = () => {
      if ('page' in link) {
        queryParams['page'] = link.page;
        setCurrentPage(link.page);
      } else {
        queryParams['page'] = 1;
        setCurrentPage(1);
      }
    };
    startPageCount();
    submitFilter(link);
  }, [props.location.search, queryParams, setCurrentPage, submitFilter]);

  const onPageChanged = (currentPage) => {
    let setNewCurrentPage = currentPage.selected + 1;
    let link = queryString.parse(props.location.search);
    setCurrentPage(setNewCurrentPage);
    link['page'] = setNewCurrentPage;
    submitFilter(link);
    window.scroll(0, 0);
  };

  return (
    <>
      <div className="BankPage__container">
        <div className="BankPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="BankPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="BankPage__breadcrumbs__slash" />
            <Link
              to={`/bank/?page=1`}
              className="BankPage__breadcrumbs__second-link">
              Федеральный реестр программ
            </Link>
          </div>
          <div />
        </div>
        <div className="BankPage">
          <div />
          <div>
            <div className="BankPage__title smaller-title">
              Федеральный реестр дополнительных профессиональных программ
            </div>
            <div className="BankPage__under-title">
              Лучшие дополнительные профессиональные программы педагогического
              образования субъектов Российской Федерации, прошедшие
              профессионально-общественную экспертизу и предназначенные для
              обучения педагогических работников и управленческих кадров
              субъектов Российской Федерации
            </div>
            <div className="hight-res-filers" style={{ marginBottom: '30px' }}>
              <div className="RegistryPage__upper-filter">
                <div className="RegistryPage__search-container">
                  <input
                    type="text"
                    className="RegistryPage__search-select__input"
                    placeholder="Поисковое слово или запрос"
                    value={fullSearch}
                    onChange={handleFullSearch}
                  />
                </div>
                <div>
                  <CustomSelect
                    class="RegionSelect"
                    name="select"
                    items={regions}
                    onChange={handleRegion}
                    selectedValue={selectedRegion}
                    zIndex={12}
                  />
                </div>
              </div>
              <div className="RegistryPage__middle-filter-upper">
                <CustomSelect
                  class="CircleSelect"
                  name="select"
                  items={year}
                  onChange={handleYear}
                  selectedValue={selectedYear}
                  zIndex={12}
                />
                <CustomSelect
                  class="CircleSelect"
                  name="select"
                  items={subjects}
                  onChange={handleSubject}
                  selectedValue={selectedSubject}
                  zIndex={10}
                />
              </div>
              <div className="RegistryPage__middle-filter-lower">
                <input
                  type="text"
                  className="filterInput "
                  placeholder="Автор"
                  value={searchAuthor}
                  onChange={handleInputSearch}
                />
                <input
                  type="text"
                  className="filterInput "
                  placeholder="Организация"
                  onChange={handleInputOrganisation}
                  value={organisationSearch}
                />
                <CustomSelect
                  class="CircleSelect"
                  name="select"
                  items={educationForm}
                  onChange={handleEducationFormChange}
                  selectedValue={selectedEducationForm}
                  zIndex={8}
                />
              </div>
              <div className="RegistryPage__lower-filter">
                <div className="RegistryPage__time-container margin_top ">
                  Срок обучения:
                  <div className="RegistryPage__date-category">
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="От"
                      value={courseOfTrainingFrom}
                      onChange={handleCourseOfTrainingFrom}
                      maxLength="3"
                    />
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="До"
                      value={courseOfTrainingTo}
                      onChange={handleCourseOfTrainingTo}
                      maxLength="3"
                    />
                  </div>
                  <span className="RegistryPage__time-container__time-span">
                    / часов
                  </span>
                </div>
                <div className="RegistryPage__sort-container ">
                  Cортировать:
                  <div className={`RegistryPage__arrow`} />
                  <span className="RegistryPage__sort-category">
                    <CustomSelect
                      class="AlphabetSelect"
                      name="select"
                      items={sortTab}
                      onChange={handleSortingTab}
                      selectedValue={selectedSortingTab}
                    />
                  </span>
                </div>
                <div className="buttons-container">
                  <button
                    className={`RegistryPage__check-btn ${
                      Object.keys(queryParams).length < 3 ? `disbl-btn` : ``
                    }`}
                    onClick={
                      Object.keys(queryParams).length > 2 ? clearBtn : () => {}
                    }>
                    Очистить
                  </button>
                  <button
                    className="RegistryPage__check-btn"
                    onClick={() => {
                      console.log(queryParams);
                      return submitFilter(queryParams);
                    }}>
                    Применить
                  </button>
                </div>
              </div>
            </div>
            {loadingCards ? (
              <div className="spinner-wrapper">
                <img src={spinner} alt="" />
              </div>
            ) : (
              infoData &&
              infoData.map((item) => <BankCard data={item} key={item.id} />)
            )}
            {totalPages > 1 && (
              <div>
                {totalPages && totalPages > 0 && !loadingCards && (
                  <ReactPaginate
                    previousLabel={'предыдущая'}
                    nextLabel={'следующая'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={onPageChanged}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    forcePage={parseInt(queryParams.page - 1)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const BankCard = (props) => {
  return (
    <div className="BankCard">
      <div className="BankCard__title">
        <Link
          to={`/bank/detail/${props.data.id}`}
          className="BankCard__title-span">
          {props.data.education_program}
        </Link>
      </div>
      <div className="BankCard__lower-container">
        <div className="BankCard__name">{props.data.author}</div>
        <div className="BankCard__place">
          {props.data.education_organisation}
        </div>
        <div className="BankCard__publishDate">
          Дата размещения в реестре:{' '}
          {props.data.reestrDate &&
            moment(props.data.reestrDate).format('DD.MM.YYYY')}
        </div>
        {/* <div className="BankCard__review">
          <a href={props.data.link}>платформа, на которой реализуется ЭОП</a>
        </div> */}
        {props.data.file[0] !== undefined ? (
          <div className="BankCard__download-wrapper">
            <span className="BankCard__download-text">Загрузить</span>
            <span className="BankCard__pdf">
              {props.data.file.map((item, id) => (
                <a
                  key={id}
                  href={`${process.env.REACT_APP_API_URL}/uploads/${item.dir}/${item.filename}`}
                  download={item.realname}>
                  {extentionChecker(item)}
                </a>
              ))}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BankPageList;
