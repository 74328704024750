import React, { useState } from "react";
import "./CollapsibleComponent.scss";

import arrow from "../../../assets/images/svg/arrow-down.svg";

const Collapsible = props => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {props.filters && (
        <div className="collapsible-block" onClick={() => setOpen(!open)}>
          <button className="RegistryPage__check-btn low-res-filter-btn">
            фильтр
          </button>
        </div>
      )}
      {open ? (
        <div className={`collapsible-content`}>{props.children}</div>
      ) : null}
      <div className="InformationPage__list-collapse-btn-wrapper">
        <div className="collapsible-block" onClick={() => setOpen(!open)}>
          {props.info && open === false && <span>показать весь список</span>}
          {props.info && open === true && <span>скрыть</span>}
          {props.info && (
            <img
              src={arrow}
              className={` collapsible-arrow ${
                open === true ? "active-arrow" : ""
              }`}
              alt=""
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Collapsible;
