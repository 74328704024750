import { useState } from "react";

const useModal = (props) => {
  const [isShowing, setIsShowing] = useState(false);
  const [isShowingLoginForm, setIsShowingLogin] = useState(false);
  const [isShowingRegistrationForm, setIsShowingRegistration] = useState(false);
  const [isShowingConfirmModal, setIsShowingConfirm] = useState(false);
  const [isShowingPedModal, setIsShowingPedModal] = useState(false);
  const [isShowingIframmeModal, setIsShowingIframmeModal] = useState(false);
  const [isShowingConfirmDPO, setIsShowingConfirmDPO] = useState(false);
  const [isShowingErrorDPO, setIsShowingErrorDPO] = useState(false);
  const [isShowingErrorModal, setIsShowingErrorModal] = useState(false)
  const [isShowingRegistrationDPOForm, setIsShowingRegistrationDPOForm] = useState(false)

  function toggleOpenErrorDPO() {
    setIsShowingErrorDPO(true);
  }
  function toggleCloseErrorDPO() {
    setIsShowingErrorDPO(false);
  }

  function toggleOpenConfirmDPO() {
    setIsShowingConfirmDPO(true);
  }
  function toggleCloseConfirmDPO() {
    setIsShowingConfirmDPO(false);
  }

  function toggle() {
    setIsShowing(true);
    document.body.style.overflow = "hidden";
  }

  const toggleOpenError = () => {
    setIsShowingErrorModal(true)
  }

  const toggleCloseError = () => {
    setIsShowingErrorModal(false)
  }

  function toggleBack() {
    setIsShowing(false);
    document.body.style.overflow = "auto";
  }

  function toggleOpenLogin() {
    setIsShowingLogin(true);
  }
  function toggleCloseLogin() {
    setIsShowingLogin(false);
  }

  function toggleOpenRegistration() {
    setIsShowingRegistration(true);
  }
  function toggleCloseRegistration() {
    setIsShowingRegistration(false);
  }

  function toggleOpenRegistrationDPO() {
    setIsShowingRegistrationDPOForm(true);
  }
  function toggleCloseRegistrationDPO() {
    setIsShowingRegistrationDPOForm(false);
  }

  function toggleOpenConfirm() {
    setIsShowingConfirm(true);
  }
  function toggleCloseConfirm() {
    setIsShowingConfirm(false);
  }

  function pedFormToggle() {
    setIsShowingPedModal(true);
    document.body.style.overflow = "hidden";
  }

  function pedFormToggleBack() {
    setIsShowingPedModal(false);
    document.body.style.overflow = "auto";
  }

  function frameModalToggle() {
    console.log(window.location.pathname)
    props.history.push("#login")
    setIsShowingIframmeModal(true);
    document.body.style.overflow = "hidden";
  }

  function frameModalToggleBack() {
    setIsShowingIframmeModal(false);
    document.body.style.overflow = "auto";
  }

  return {
    isShowing,
    toggle,
    toggleBack,
    isShowingLoginForm,
    toggleOpenLogin,
    toggleCloseLogin,
    isShowingRegistrationForm,
    toggleOpenRegistration,
    toggleCloseRegistration,
    isShowingConfirmModal,
    toggleOpenConfirm,
    toggleCloseConfirm,

    toggleOpenErrorDPO,
    toggleCloseErrorDPO,
    isShowingErrorDPO,

    toggleOpenConfirmDPO,
    toggleCloseConfirmDPO,
    isShowingConfirmDPO,

    toggleOpenRegistrationDPO,
    toggleCloseRegistrationDPO,
    isShowingRegistrationDPOForm,

    toggleOpenError,
    toggleCloseError,
    isShowingErrorModal,

    pedFormToggle,
    pedFormToggleBack,
    isShowingPedModal,

    frameModalToggle,
    frameModalToggleBack,
    isShowingIframmeModal
  };
};

export default useModal;
