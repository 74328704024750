import { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";
import "moment/locale/ru";

const setYears = (yearNow) => {
  const initYear = 2019
  return new Array(yearNow - initYear + 1).fill('').map((node, idx) => ({
    id: initYear +  idx,
    name: `${initYear +  idx}`
  }))
}

const useFilterHook = props => {
  const [catalogList, setCatalogList] = useState(null);
  const [queryParams, setParams] = useState({});
  const [filterURL, setFilterURL] = useState(-1);
  const [regions, setRegions] = useState({ id: 0, name: "Все регионы" });
  const [subjects, setSubjects] = useState({
    id: 0,
    name: "Предметная направленность"
  });
  const [year, setYear] = useState({
    id: 0,
    name: "Год"
  });
  const [educationForm, setEducationForm] = useState({
    id: 0,
    name: "Форма обучения"
  });
  const [categories, setCategories] = useState({
    id: 0,
    name: "Все категории"
  });
  const [sortTab, setSortTab] = useState({ name: "по алфавиту", id: 1 });

  const [currentCards, setCurrentCards] = useState([]);
  const [selectedInputSelect, setSelectedInputSelect] = useState({
    name: "По содержанию",
    id: 0
  });
  const [fullSearch, setFullSearch] = useState("");
  const [searchAuthor, setAuthorSearch] = useState("");
  const [selectedYear, setSelectedYear] = useState({
    id: 0,
    name: "Выберите год"
  })

  const [selectedRegion, setSelectedRegion] = useState({
    id: 0,
    name: "Все регионы"
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState({
    id: 0,
    name: "Направленность программы"
  });
  const [selectedEducationForm, setSelectedEducationForm] = useState({
    id: 0,
    name: "Форма обучения"
  });
  const [selectedCategoryTab, setCategoryTab] = useState({
    id: 0,
    name: "Все категории"
  });
  const [selectedSortingTab, setSortingTab] = useState({
    name: "по алфавиту",
    id: 1
  });
  const [organisationSearch, setOrganisationSearch] = useState("");
  const [courseOfTrainingFrom, setcourseOfTrainingFrom] = useState(null);
  const [courseOfTrainingTo, setCourseOfTrainingTo] = useState(null);

  const [isActiveSorting, setIsActiveSorting] = useState(false);
  const [pageChange, setNewCurrentCards] = useState(1);
  const [currentCardsPagination, setCurrentCardsPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [loadingCards, setLoadingCards] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let link = queryString.parse(props.location.search);

    const fetchYears = async () => {
      const yearNow = new Date()
      const yearOptions = setYears(yearNow.getFullYear())
      setYear(yearOptions)
      if ('year' in link) {
        setSelectedYear(
          Object.values(yearOptions).filter(
            item => item.id === parseInt(link.year)
          )[0]
        )
        queryParams['year'] = link.year
      }
    }

    //загрузка регионов
    const fetchRegions = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/regions`);
      setRegions(result.data);
      if ("region" in link) {
        setSelectedRegion(
          Object.values(result.data).filter(
            item => item.id === parseInt(link.region)
          )[0]
        );
        queryParams["region"] = link.region;
      }
    };

    //загрузка предметов
    const fetchSubjects = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/expertise-theme`);
      setSubjects(result.data);
      if ("expertiseTheme" in link) {
        setSelectedSubject(
          Object.values(result.data).filter(
            item => item.id === parseInt(link.expertiseTheme)
          )[0]
        );
        queryParams["expertiseTheme"] = link.expertiseTheme;
      }
    };

    //загрузка форм обучения
    const fetchEducationForm = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/education-form`);
      setEducationForm(result.data);
      if ("educationForm" in link) {
        setSelectedEducationForm(
          Object.values(result.data).filter(
            item => item.id === parseInt(link.educationForm)
          )[0]
        );
        queryParams["educationForm"] = link.educationForm;
      }
    };

    //загрузка категорий
    const fetchCategories = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/additional-professional-programm`);
      setCategories(result.data);
      if ("additionalProfessionalProgram" in link) {
        setCategoryTab(
          Object.values(result.data).filter(
            item => item.id === parseInt(link.additionalProfessionalProgram)
          )[0]
        );
        queryParams["additionalProfessionalProgram"] =
          link.additionalProfessionalProgram;
      }
    };

    //загрузка фильтров сортировки
    const fetchSortFilters = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/programs-sort`);
      setSortTab(result.data);
      if ("sortType" in link) {
        setSortingTab(
          Object.values(result.data).filter(
            item => item.id === parseInt(link.sortType)
          )[0]
        );
      }
    };

    const startPageCount = () => {
      if ("page" in link) {
        queryParams["page"] = link.page;
        setCurrentPage(link.page);
      } else {
        queryParams["page"] = 1;
        setCurrentPage(1);
      }
      queryParams["sortType"] = 1;
      setSortingTab(1);
    };

    if ("dateFrom" in link) {
      setStartDate(moment(link.dateFrom, moment.defaultFormat).toDate());
      queryParams["dateFrom"] = moment(link.dateFrom).format("YYYY-MM-D");
    }

    if ("dateTo" in link) {
      setStartDate(moment(link.dateTo, moment.defaultFormat).toDate());
      queryParams["dateTo"] = moment(link.dateTo).format("YYYY-MM-D");
    }

    if ("search" in link) {
      setFullSearch(link.search);
      queryParams["search"] = link.search;
    }

    if ("author" in link) {
      setAuthorSearch(link.author);
      queryParams["author"] = link.author;
    }

    if ("organization" in link) {
      setOrganisationSearch(link.organization);
      queryParams["organization"] = link.organization;
    }

    fetchRegions();
    fetchSubjects();
    fetchEducationForm();
    fetchCategories();
    fetchSortFilters();
    startPageCount();
    fetchYears()
    submitFilter(link);
  }, []);

  const handleFullSearch = e => {
    setFullSearch(e.target.value);
    queryParams["search"] = e.target.value;
  };

  const handleRegion = newValue => {
    setSelectedRegion(newValue);
    queryParams["region"] = newValue.id;
  };

  const handleYear = newValue => {
    setSelectedYear(newValue);
    queryParams["year"] = newValue.id;
  };

  const handleSubject = newValue => {
    setSelectedSubject(newValue);
    queryParams["expertiseTheme"] = newValue.id;
  };

  const handleEducationFormChange = newValue => {
    setSelectedEducationForm(newValue);
    queryParams["educationForm"] = newValue.id;
  };

  const handleCategoryTabChange = newValue => {
    setCategoryTab(newValue);
    queryParams["additionalProfessionalProgram"] = newValue.id;
  };

  const handleStartDate = newValue => {
    setStartDate(newValue);
    queryParams["dateFrom"] = moment(newValue).format("YYYY-MM-D");
  };

  const handleEndDate = newValue => {
    setEndDate(newValue);
    queryParams["dateTo"] = moment(newValue).format("YYYY-MM-D");
  };

  const handleCourseOfTrainingFrom = e => {
    setcourseOfTrainingFrom(e.target.value);
    queryParams["courseOfTrainingFrom"] = e.target.value;
  };

  const handleCourseOfTrainingTo = e => {
    setCourseOfTrainingTo(e.target.value);
    queryParams["courseOfTrainingTo"] = e.target.value;
  };

  const handleInputSearch = e => {
    setAuthorSearch(e.target.value);
    queryParams["author"] = e.target.value;
  };

  const handleInputOrganisation = e => {
    setOrganisationSearch(e.target.value);
    queryParams["organization"] = e.target.value;
  };

  const handleSortingTab = newValue => {
    setSortingTab(newValue);
    queryParams["sortType"] = newValue.id;
  };

  const onPageChanged = currentPage => {
    let setNewCurrentPage = currentPage.selected + 1;
    setCurrentPage(setNewCurrentPage);
    queryParams["page"] = setNewCurrentPage;
    submitFilter(queryParams);
  };

  const submitFilter = params => {
    let query = Object.keys(params)
      .map(
        key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");
    let url = `${process.env.REACT_APP_API_URL}/api/v1/programs?` + query;
    setFilterURL(query);

    const fetchData = async () => {
      setIsError(false);
      setLoadingCards(true);
      try {
        const result = await axios(url);
        setTotalPages(result.data.pagination.total_pages);
        setCatalogList(result.data.items);
      } catch (error) {
        setIsError(true);
      }
      setLoadingCards(false);
    };

    fetchData();

    props.history.push({
      search: `?${query}`
    });
  };

  const clearBtn = () => {
    delete queryParams["search"];
    setFullSearch("");
    delete queryParams["author"];
    setAuthorSearch("");
    delete queryParams["region"];
    setSelectedRegion({ id: 0, name: "Все регионы" });
    delete queryParams['year']
    setSelectedYear({
      id: 0,
      name: "Выберите год"
    })
    delete queryParams["dateFrom"];
    setStartDate(null);
    delete queryParams["dateTo"];
    setEndDate(null);

    delete queryParams["courseOfTrainingFrom"];
    setcourseOfTrainingFrom("");
    delete queryParams["courseOfTrainingTo"];
    setCourseOfTrainingTo("");

    delete queryParams["expertiseTheme"];
    setSelectedSubject({
      id: 0,
      name: "Предметная направленность"
    });
    delete queryParams["educationForm"];
    setSelectedEducationForm({
      id: 0,
      name: "Форма обучения"
    });
    delete queryParams["additionalProfessionalProgram"];
    setCategoryTab({
      id: 0,
      name: "Все категории"
    });
    delete queryParams["organization"];
    setOrganisationSearch("");
    setSortingTab({
      name: "по алфавиту",
      id: 1
    });

    queryParams["page"] = 1;
    setCurrentPage(1);

    submitFilter({ page: 1, sortType: 1 });
  };

  const SortingAsc = () => {
    let sortingTabId = selectedSortingTab.id;
    const copyData = [...currentCards];
    const collator = new Intl.Collator();

    let sortedData = copyData.sort(function sortingData(a, b) {
      if (isActiveSorting) {
        switch (sortingTabId) {
          case 2:
            return collator.compare(a.location, b.location);
          default:
            return collator.compare(a.title, b.title);
        }
      } else {
        switch (sortingTabId) {
          case 2:
            return collator.compare(b.location, a.location);
          default:
            return collator.compare(b.title, a.title);
        }
      }
    });

    toggleSorting();

    setCurrentCards(sortedData);
  };

  const toggleSorting = () => {
    setIsActiveSorting(!isActiveSorting);
  };

  const getCurrentCards = (
    catalogList,
    selectedCategoryTab,
    selectedSubjectTab
  ) => {
    let currentCards;

    if (
      (selectedCategoryTab.id === undefined || selectedCategoryTab.id === 0) &&
      (selectedSubjectTab.id === undefined || selectedSubjectTab.id === 0)
    ) {
      return catalogList;
    } else if (
      selectedCategoryTab.id === undefined ||
      selectedCategoryTab.id === 0
    ) {
      currentCards = catalogList.filter(
        catalogItem => catalogItem.subject_id === selectedSubjectTab.id
      );
    } else if (
      selectedSubjectTab.id === undefined ||
      selectedSubjectTab.id === 0
    ) {
      currentCards = catalogList.filter(
        catalogItem => catalogItem.category_id === selectedCategoryTab.id
      );
    } else {
      currentCards = catalogList.filter(
        catalogItem =>
          catalogItem.category_id === selectedCategoryTab.id &&
          catalogItem.subject_id === selectedSubjectTab.id
      );
    }
    return currentCards;
  };

  const newCurrentCards = currentPage => {
    const pageLimit = 6;
    const offset = (currentPage - 1) * pageLimit;
    const currentCardsPagination = currentCards.slice(
      offset,
      offset + pageLimit
    );
    const totalPages = Math.ceil(currentCards.length / pageLimit);
    setCurrentPage(currentPage);
    setCurrentCardsPagination(currentCardsPagination);
    setTotalPages(totalPages);
  };

  return {
    year,
    setYear,
    selectedYear,
    catalogList,
    handleYear,
    regions,
    subjects,
    educationForm,
    categories,
    handleInputOrganisation,
    organisationSearch,
    sortTab,

    currentCards,
    selectedInputSelect,
    searchAuthor,
    handleInputSearch,
    selectedCategoryTab,
    handleCategoryTabChange,
    handleEducationFormChange,
    handleSortingTab,
    SortingAsc,
    selectedSortingTab,
    onPageChanged,
    currentCardsPagination,

    selectedRegion,
    handleRegion,
    selectedSubject,
    handleSubject,
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    selectedEducationForm,

    queryParams,
    submitFilter,
    clearBtn,
    totalPages,
    handleFullSearch,
    fullSearch,
    loadingCards,
    isError,

    courseOfTrainingFrom,
    handleCourseOfTrainingFrom,
    courseOfTrainingTo,
    handleCourseOfTrainingTo
  };
};

export default useFilterHook;
