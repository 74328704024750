import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import spinner from '../../assets/images/loader.gif';
import useFetch from '../../helpers/useFetch';
import Button from '../UI/Button/Button';
import Error from '../UI/Error/Error';
import './ArticlePage.scss';
import SimilarArticles from './SimilarArticles';

const ArticlePage = ({ match }) => {
  window.scrollTo(0, 0);
  const [article, setArticle] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const [articleResult, articleError, getRequestToArticle] = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/journal/vektor/${match.params.articleId}`,
  );

  useEffect(() => {
    if (articleResult) {
      setArticle(articleResult);
      setLoaded(true);
    }
  }, [articleResult]);

  useEffect(() => {
    articleError && setLoaded(true);
  }, [articleError]);

  useEffect(() => {
    getRequestToArticle();
    setLoaded(false);
  }, [match.params.articleId]);

  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div className="AboutPage__container">
        <div className="AboutPage__breadcrumbs">
          <div />
          <div style={{ maxWidth: '550px' }}>
            <Link to="/" className="AboutPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <Link to="/seminar" className="AboutPage__breadcrumbs__main-link">
              Семинар
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <Link to="/blog" className="AboutPage__breadcrumbs__main-link">
              {' '}
              Журнал «Вектор образования»
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <br />
            <Link className="AboutPage__breadcrumbs__second-link">
              {' '}
              {article.title ? article.title : ''}
            </Link>
          </div>
          <div />
        </div>
        <div className="AboutPage">
          <div />
          <div className="AboutPage__block">
            <div className="AboutPage__title">
              {article.title ? article.title : ''}
            </div>
            <div className="AboutPage__preview">
              {article.preview ? article.preview : ''}
            </div>
            <div />
            <div className="AboutPage__content">
              {isLoaded ? (
                <>
                  {!articleError ? (
                    <>
                      <div className="ArticlePage">
                        {ReactHtmlParser(article.text)}
                        <div className="button-wrapper">
                          {Button(
                            'ArticlePage__button',
                            'к семинару',
                            '/seminar',
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <Error />
                  )}
                </>
              ) : (
                <div className="spinner-wrapper">
                  <img src={spinner} alt="" />
                </div>
              )}
            </div>
          </div>
          <div />
        </div>
        <SimilarArticles currentArticleId={match.params.articleId} />
      </div>
    </>
  );
};

export default ArticlePage;
