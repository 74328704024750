import React, { useEffect, useState } from 'react';

export const RootContext = React.createContext();

export const RootContextProvider = (props) => {
  const [searchData, setSearchData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');


  return (
    <RootContext.Provider
    >
      {props.children}
    </RootContext.Provider>
  );
};
