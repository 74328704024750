import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Registration.scss';
import {
  ConfirmModalDPO,
  ErrorModalDPO,
  RegistrationDPO,
} from './RegistrationDPO';

export const RegistrationDPOModal = () => {
  const [
    isShowingRegistrationDPOForm,
    setIsShowingRegistrationDPOForm,
  ] = useState(true);
  const [isShowingConfirmDPO, setIsShowingConfirmDPO] = useState(false);
  const [isShowingErrorDPO, setIsShowingErrorDPO] = useState(false);

  const history = useHistory();
  return (
    <>
      <RegistrationDPO
        toggleCloseRegistrationDPO={() => {
          setIsShowingRegistrationDPOForm(false);

          history.goBack();
        }}
        isShowingRegistrationDPOForm={isShowingRegistrationDPOForm}
        toggleOpenConfirmDPO={() => {
          setIsShowingConfirmDPO(true);
        }}
        toggleOpenErrorDPO={() => {
          setIsShowingErrorDPO(true);
        }}
      />
      <ConfirmModalDPO
        toggleCloseConfirmDPO={() => {
          setIsShowingConfirmDPO(false);
        }}
        isShowingConfirmDPO={isShowingConfirmDPO}
      />
      <ErrorModalDPO
        toggleCloseErrorDPO={() => {
          setIsShowingErrorDPO(false);
        }}
        isShowingErrorDPO={isShowingErrorDPO}
      />
    </>
  );
};
