import React from "react";
import { Link } from "react-router-dom";
import "./AboutPage.scss";

const AboutPage = () => {
  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div className="AboutPage__container">
        <div className="AboutPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="AboutPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <Link className="AboutPage__breadcrumbs__second-link">
              О проекте
            </Link>
          </div>
          <div />
        </div>
        <div className="AboutPage">
          <div />
          <div>
            <div className="AboutPage__title">
              Многофункциональный портал ДПО{" "}
            </div>
            <div></div>
            <div className="AboutPage__content">
              <div>
                <p className="AboutPage__content__p">
                  Решение задач по созданию современной и безопасной цифровой
                  образовательной среды в Российской Федерации обеспечит
                  качество и доступность образования всех уровней.
                </p>
                <p
                  className="AboutPage__content__p"
                  style={{ marginBottom: "26px" }}
                >
                  Развитие цифровой образовательной среды в системе
                  дополнительного профессионального образования изменит подход к
                  разработке дополнительных профессиональных программ с
                  применением электронного обучения и дистанционных
                  образовательных технологий.
                </p>
              </div>
              <div className="AboutPage__content__list">
                <div className="AboutPage__content__title">
                  Цифровая образовательная среда дополнительного
                  профессионального образования создаст условия для:
                </div>
                <ul>
                  <li className="AboutPage__content__item">
                    формирования инфраструктуры цифровой образовательной среды
                    дополнительного профессионального образования
                  </li>
                  <li className="AboutPage__content__item">
                    функционирования федерального электронного реестра
                    образовательных программ дополнительного профессионального
                    образования
                  </li>
                  <li className="AboutPage__content__item">
                    проведения профессионально-общественной экспертизы
                    дополнительных профессиональных программ на едином
                    федеральном портале дополнительного профессионального
                    педагогического образования
                  </li>
                  <li className="AboutPage__content__item">
                    использования электронных образовательных программ и
                    ресурсов для организации обучения педагогических работников
                  </li>
                </ul>
              </div>
              <div>
                <p
                  className="AboutPage__content__p"
                  style={{ marginTop: "31px" }}
                >
                  Единый федеральный портал дополнительного профессионального
                  педагогического образования создан для формирования единого
                  образовательного пространства и развития цифровой
                  образовательной среды дополнительного профессионального
                  образования.
                </p>
                <p className="AboutPage__content__p">
                  Портал консолидирует образовательные возможности участников
                  системы дополнительного профессионального образования,
                  унифицирует подходы к обучению педагогических работников и
                  развитию их профессиональных компетенций.
                </p>
                <p className="AboutPage__content__p">
                  На портале размещена методическая, организационная и
                  нормативно-правовая информация для педагогических работников,
                  авторов и разработчиков программ, экспертов, преподавателей,
                  администрации образовательных организаций дополнительного
                  профессионального образования.
                </p>
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default AboutPage;
