import React, { useState } from "react";
import "./Slider.scss";
import "../../../components/ArticlePage/SimilarArticles.scss"
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
const Slider = ({items}) => {

    const [currentItem, setCurrentItem] = useState(0);

    let widthContentArea = window.innerWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;

    const onPrevSlide = () => {
        if (currentItem === 0) {
            setCurrentItem(items.length - 1)
        }
        else {
            setCurrentItem(currentItem - 1)
        }
    }

    const onNextSlide = () => {
        if (currentItem === items.length - 1) {
            setCurrentItem(0)
        }
        else {
            setCurrentItem(currentItem + 1)
        }
    }

    return (
        <>
            { widthContentArea > 860 &&
                items.map(article => (
                    <div key={article.id} className='SimilarArticles__item'>
                        <Link to={`/blog/${article.id}`}>
                            <img src={article.preview_picture.previews.c_vector360x260} alt="" className="SimilarArticles__img"/>
                            <div className="SimilarArticles__title">
                                {article.title}
                            </div>
                            <div className="SimilarArticles__sign">
                                {ReactHtmlParser(article.preview)}
                            </div>
                        </Link>
                    </div>
                ))
            }
            { widthContentArea <= 860 &&
                <div className='Slider'>
                    <div className="arrow arrow-prev" onClick={onPrevSlide}>
                        <div className="arrow-mask arrow-mask--prev">

                        </div>
                    </div>
                    <div className='SimilarArticles__item'>
                        <Link to={`/blog/${items[currentItem].id}`}>
                            <img src={items[currentItem].preview_picture.previews.c_vector360x260} alt="" className="SimilarArticles__img"/>
                            <div className="SimilarArticles__title">
                                {items[currentItem].title}
                            </div>
                            <div className="SimilarArticles__sign">
                                {ReactHtmlParser(items[currentItem].preview)}
                            </div>
                        </Link>
                    </div>
                    <div className="arrow arrow-next" onClick={onNextSlide}>
                        <div className="arrow-mask arrow-mask--next">

                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Slider;
