import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import eye from '../../assets/images/svg/eye.svg';
import extentionChecker from '../../helpers/extentionChecker';
import useModal from '../../helpers/useModal';
import Collapsible from '../UI/Collapsible/CollapsibleComponent';
import Modal from '../UI/Modal/Modal';
import './InformationPage.scss';

const InformationPage = () => {
  const [infoData, setInfoData] = useState(null);
  const [methodData, setMethodData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [normData, setNormData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/info-materials`);
      setInfoData(result.data.items);
      setMethodData(result.data.items.filter((item) => item.id === 1));
      setOrgData(result.data.items.filter((item) => item.id === 2));
      setNormData(result.data.items.filter((item) => item.id === 3));
    };

    fetchData();
  }, [setInfoData]);

  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div className="InformationPage__container">
        <div className="InformationPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="InformationPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="InformationPage__breadcrumbs__slash" />
            <Link to="#" className="InformationPage__breadcrumbs__second-link">
              Информационные материалы
            </Link>
          </div>
          <div />
        </div>
        <div className="InformationPage">
          <div />
          <div>
            <div className="InformationPage__title">
              Информационные материалы
            </div>
            <div className="InformationPage__search"></div>
            {methodData &&
              methodData.length > 0 &&
              methodData[0].catalogs !== undefined && (
                <>
                  <div className="InformationPage__category-name">
                    Методическая информация
                  </div>
                  {methodData[0].catalogs.slice(0, 2).map((item) => (
                    <InformationCard
                      key={item.id}
                      title={item.title}
                      file={item.file}
                    />
                  ))}
                  {methodData[0].catalogs.length > 2 && (
                    <Collapsible info>
                      {methodData[0].catalogs.slice(2).map((item) => (
                        <InformationCard
                          key={item.id}
                          title={item.title}
                          file={item.file}
                        />
                      ))}
                    </Collapsible>
                  )}
                </>
              )}
            {orgData &&
              orgData.length > 0 &&
              orgData[0].catalogs !== undefined && (
                <>
                  <div className="InformationPage__category-name">
                    Организационная информация
                  </div>
                  {orgData[0].catalogs.slice(0, 2).map((item) => (
                    <InformationCard
                      key={item.id}
                      title={item.title}
                      file={item.file}
                    />
                  ))}
                  {orgData[0].catalogs.length > 2 && (
                    <Collapsible>
                      {orgData[0].catalogs.slice(2).map((item) => (
                        <InformationCard
                          key={item.id}
                          title={item.title}
                          file={item.file}
                        />
                      ))}
                    </Collapsible>
                  )}
                </>
              )}
            {normData &&
              normData.length > 0 &&
              normData[0].catalogs !== undefined && (
                <>
                  <div className="InformationPage__category-name">
                    Нормативно-правовая информация
                  </div>
                  {normData[0].catalogs.slice(0, 2).map((item) => (
                    <InformationCard
                      key={item.id}
                      title={item.title}
                      file={item.file}
                    />
                  ))}
                  {normData[0].catalogs.length > 2 && (
                    <Collapsible>
                      {normData[0].catalogs.slice(2).map((item) => (
                        <InformationCard
                          key={item.id}
                          title={item.title}
                          file={item.file}
                        />
                      ))}
                    </Collapsible>
                  )}
                </>
              )}
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

const InformationCard = (props) => {
  const { isShowing, toggle, toggleBack } = useModal();

  let filter_extention = props.file.filter(
    (item) =>
      item.filename.split('.').pop() === 'pdf' ||
      item.filename.split('.').pop() === 'PDF',
  );

  return (
    <div className="InformationCard">
      <div className="InformationCard__title">{props.title}</div>
      <div className="InformationCard__lower-container">
        {filter_extention.length !== 0 ? (
          <div className="InformationCard__review" onClick={toggle}>
            <img className="InformationCard__review__eye" src={eye} alt="" />
            <span>Предпросмотр</span>
          </div>
        ) : null}
        <div className="InformationCard__download-wrapper">
          {props.file.length !== 0 ? (
            <span className="InformationCard__download-text">Загрузить</span>
          ) : null}
          {props.file.map((item, id) => (
            <>
              <a
                key={id}
                href={`${process.env.REACT_APP_API_URL}/uploads/${item.dir}/${item.filename}`}
                download={item.realname}
                className="InformationCard__pdf">
                {extentionChecker(item)}
              </a>
            </>
          ))}
        </div>
      </div>
      {filter_extention[0] !== undefined ? (
        <Modal toggleBack={toggleBack} isShowing={isShowing}>
          <iframe
            src={`${process.env.REACT_APP_API_URL}/uploads/${filter_extention[0].dir}/${filter_extention[0].filename}`}
            title="PortletIFrameWidget"
            className="iframe-modal"
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default InformationPage;
