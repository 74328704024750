import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import doc from '../../assets/images/svg/doc-new.svg';
import pdf from '../../assets/images/svg/pdf.svg';
import './BankPage.scss';
import BankPageList from './BankPageList';
import FullBankProgramm from './FullBankProgramm/FullBankProgramm';

const BankPage = () => {
  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <Switch>
        <Route path="/bank/" exact component={BankPageList} />
        <Route path="/bank/detail/:id" component={FullBankProgramm} />
      </Switch>
    </>
  );
};

const BankCard = () => {
  return (
    <div className="BankCard">
      <div className="BankCard__title">
        <Link to={`/bank/detail/1`} className="BankCard__title-span">
          Современные подходы к работе с детьми раннего возраста в дошкольной
          образовательной организации
        </Link>
      </div>
      <div className="BankCard__lower-container">
        <div className="BankCard__name">Зотова Анна Алексеевна</div>
        <div className="BankCard__place">
          МБОУ "Лицей казачества имени А.Ф. Дьякова"
        </div>
        <div className="BankCard__review">sch15uz.mskobr.ru</div>
        <div className="BankCard__download-wrapper">
          <span className="BankCard__download-text">Зарузить</span>
          <span className="BankCard__pdf">
            <img className="BankCard__pdf__link" src={pdf} alt="" />
          </span>
          <span className="BankCard__doc">
            <img className="BankCard__doc__link" src={doc} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default BankPage;
