import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmModal, ErrorModal, LoginFormModal } from './Registration';
import './Registration.scss';

export const RegistrationModal = () => {
  const [isShowingRegistrationForm, setIsShowingRegistration] = useState(true);
  const [isShowingConfirmModal, setIsShowingConfirm] = useState(false);
  const [isShowingErrorModal, setIsShowingErrorModal] = useState(false);

  const history = useHistory();
  return (
    <>
      <LoginFormModal
        isShowingRegistrationForm={isShowingRegistrationForm}
        toggleCloseRegistration={() => {
          setIsShowingRegistration(false);
          history.goBack();
        }}
        toggleOpenConfirm={() => {
          setIsShowingConfirm(true);
        }}
        toggleOpenError={() => {
          setIsShowingErrorModal(true);
        }}
      />
      <ConfirmModal
        isShowingConfirmModal={isShowingConfirmModal}
        toggleCloseConfirm={() => {
          setIsShowingErrorModal(true);
        }}
      />
      <ErrorModal
        toggleCloseError={() => {
          setIsShowingErrorModal(false);
        }}
        isShowingErrorModal={isShowingErrorModal}
      />
    </>
  );
};
