import React from 'react'

const CustomTab =(props)=> {

    return (
        <React.Fragment>
            {props.panes.map((pane, index)=>{
                return (
                    <div style={{display:index===props.activeIndex ? 'block': 'none'}} key={"report_tab"+index}>{pane.render()}</div>    
                )
            })}
            
        </React.Fragment>
    )
  
}
export default CustomTab