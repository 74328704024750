import { useState } from 'react';
import axios from 'axios';

const useFetch = (api) => {

    const [result, setResult] = useState({});
    const [errors, setErrors] = useState(null);

    const getRequest = (page = '') => {
        axios(`${api}${page ? '?page=' + page : ''}`).then((result) => {
            const { data } = result;
            setResult(data);
        }).catch(e => setErrors(e))
    };

    return [
        result, errors, getRequest
    ]
};

export default useFetch;
