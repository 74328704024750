import { createBrowserHistory } from 'history';
import 'normalize.css';
import React, {createContext, useEffect, useState} from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import AboutPage from '../components/AboutPage/AboutPage';
import BankPage from '../components/BankPage/BankPage';
import CatalogPage from '../components/CatalogPage/CatalogPage';
import { Page500 } from '../components/ErrorPage/ErrorPage';
import HomePage from '../components/HomePage/HomePage';
import InformationPage from '../components/InformationPage/InformationPage';
import LoadingPage from '../components/LoadingPage/LoadingPage';
import PasswordRecover from '../components/PasswordRecover/PasswordRecover';
import ProfessionalPublicDiscussion from '../components/ProfessionalPublicDiscussion/ProfessionalPublicDiscussion';
import RegistryPage from '../components/RegistryPage/RegistryPage';
import ReportPage from '../components/ReportPage/ReportPage';
import SearchPage from '../components/SearchPage/SearchPage';
import SeminarPage from '../components/SeminarPage/SeminarPage';
import SeminarPage1 from '../components/SeminarPage/SeminarPage1';
import SeminarPage2 from '../components/SeminarPage/SeminarPage2';
import TermsOfUse from '../components/TermsOfUse/TermsOfUse';
import './App.scss';
import ArticlePage from './ArticlePage/ArticlePage';
import CoursesLoginModal from './CoursesLogin/CoursesLoginModal';
import Layout from './layout';
import LoginModal from './Login/LoginModal';
import MagazinePage from './MagazinePage/MagazinePage';
import { RegistrationDPOModal } from './Registration/RegistrationDPOModal';
import { RegistrationModal } from './Registration/RegistrationModal';


export const UserContext = createContext({ userName: null, userRole: null });

const history = createBrowserHistory();

const withLayout = (Component) => (props) => {

  const [cookieAccess, setCookieAccess] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const getNameAndRole = async (token) => {
    let getUserName = null;
    let getUserRole = null;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/username/token?token=` + token, {
        method: 'GET',
      });
      if(response.ok){
        const data = await response.json();
        if(data){
          getUserName = data.name;
          getUserRole = data.role;
        }
      }
    } catch (err) { console.log(err);
    }

    if (getUserName !== null) {
      setUserName(getUserName);
    }
    if (getUserRole !== null) {
      setUserRole(getUserRole);
    }
  };

  useEffect(() => {
    const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)dppoaccess\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if(cookieValue){
      setCookieAccess(cookieValue);
    }else{
      setCookieAccess(null);
    }
  }, []);

  useEffect(() => {
    getNameAndRole(cookieAccess);
  }, [cookieAccess]);

  return (
    <UserContext.Provider value={{ userName: userName, userRole: userRole }}>
      <Layout>
        <Component {...props} />
      </Layout>
    </UserContext.Provider>
  )
};


function App() {
  const [showLoader, setShowLoader] = useState(false);

  // useEffect(() => {
  //   setShowLoader(true);
  //   const hadleLoader = setTimeout(() => {
  //     setShowLoader(false);
  //   }, 2777);
  //   return () => clearTimeout(hadleLoader);
  // }, []);

  return (
    <>
          <YMInitializer accounts={[65681692]} />
          <Router history={history}>
              <Switch>
                <Route exact path="/" component={withLayout(HomePage)} />
                {/* <Route path="/recover" component={withLayout(PasswordRecover)} /> */}
                <Route path="/about" component={withLayout(AboutPage)} />
                <Route path="/report" component={withLayout(ReportPage)} />
                <Route path="/registry" component={withLayout(RegistryPage)} />
                <Route path="/catalog" component={withLayout(CatalogPage)} />
                <Route path="/info" component={withLayout(InformationPage)} />
                <Route path="/bank" component={withLayout(BankPage)} />
                <Route path="/termsofuse" component={withLayout(TermsOfUse)} />
                <Route exact path="/seminar" component={withLayout(SeminarPage2)} />
                <Route exact path="/blog" component={withLayout(MagazinePage)} />
                <Route
                  exact
                  path="/blog/:articleId"
                  component={withLayout(ArticlePage)}
                />
                <Route path="/seminar1" component={withLayout(SeminarPage)} />
                <Route path="/seminar2" component={withLayout(SeminarPage1)} />
                <Route path="/500" component={withLayout(Page500)} />
                <Route
                  path="/discussion"
                  component={withLayout(ProfessionalPublicDiscussion)}
                />
                <Route
                  path="/coursesLogin"
                  component={withLayout(CoursesLoginModal)}
                />
                <Route path="/login" component={withLayout(LoginModal)} />
                <Route
                  path="/registrationDPO"
                  component={withLayout(RegistrationDPOModal)}
                />
                <Route
                  path="/registration"
                  component={withLayout(RegistrationModal)}
                />
              </Switch>
          </Router>
    </>
  );
}

export default App;
