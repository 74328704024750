import React from 'react';
import { Link } from 'react-router-dom';
import academyLogo from '../../../assets/images/png/academyLogo.png';
import logo from '../../../assets/images/png/gerb-icon.png';
import './Footer.scss';

const year = new Date().getFullYear();

const commit_sha = process.env.REACT_APP_COMMIT_SHA;

const Footer = () => (
  <div className="Footer__container">
    <div className="Footer__background-image" />
    <div className="Footer">
      <div />
      <div className="Footer__upper">
        <a
          href="https://edu.gov.ru/"
          target="_blank"
          rel="noopener noreferrer"
          className="Footer__upper__left-icon">
          <img src={logo} alt="minpros" />
          <span className="Footer__upper__left-icon__text">
            МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ
          </span>
        </a>
        <div className="Footer__upper__center-icon">
          <div className="Footer__upper__center-icon__phone">
            Техническая и методическая поддержка:{' '}
            <ul>
            <li><a className="email-link" href="mailto:dppo.support@apkpro.ru">
              DPPO.SUPPORT@APKPRO.RU
            </a></li>
            <li><a className="email-link" href="mailto:expertiza@apkpro.ru">
              EXPERTIZA@APKPRO.RU
            </a></li>
            </ul>
          </div>
          <Link to="/about" className="Footer__upper__center-icon__item">
            <span>О проекте</span>
          </Link>
          <Link to="/bank?page=1" className="Footer__upper__center-icon__item">
            <span>Федеральный реестр программ</span>
          </Link>
        </div>

        <a
          href="https://apkpro.ru/"
          target="_blank"
          rel="noopener noreferrer"
          className="Footer__upper__right-icon">
          <img src={academyLogo} alt="academyLogo" />
          <span className="Footer__upper__right-icon__text">
            ФГАОУ ДПО "АКАДЕМИЯ МИНПРОСВЕЩЕНИЯ РОССИИ"
          </span>
        </a>
      </div>

      <div className="Footer__lower">
        <div className="Footer__lower__copyrigth">
          © Все права защищены.{ year }
        </div>
      </div>

      <div className="Footer__commit">
        <span>#{ commit_sha }</span>
      </div>

    </div>
  </div>
);

export default Footer;
