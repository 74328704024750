import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/png/logo.png';
import InputField from './InputField';
import './ProfessionalPublicDiscussion.scss';

const initialData = {
  fullname: ``,
  organization: ``,
  email: ``,
  first_answer: '',
  second_answer: '',
  third_answer: '',
  fourth_answer: '',
  fifth_answer: '',
};

const questions = {
  first_question: 'Вопрос 1',
  second_question: 'Вопрос 2',
  third_question: 'Вопрос 3',
  fourth_question: 'Вопрос 4',
  fifth_question: 'Вопрос 5',
};

const answerOptions = [
  { answer: 'Первый вариант', checked: false },
  { answer: 'Второй', checked: true },
  { answer: 'Третий', checked: false },
];

const ProfessionalPublicDiscussion = () => {
  const [formData, setFormData] = useState(initialData);
  const [answers, setAnswers] = useState(answerOptions);

  const isButtonDisabled = Object.values(formData).includes('');

  useEffect(() => {
    const firstAnswer = answers
      .filter((it) => it.checked)
      .map((it) => it.answer)
      .join(', ');

    setFormData({
      ...formData,
      first_answer: firstAnswer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const handleChange = (fieldName) => (fieldValue) => {
    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  const handleOnChange = (index) => {
    const newAnswers = [...answers];
    const currentValue = answers[index].checked;
    newAnswers[index].checked = !currentValue;
    setAnswers(newAnswers);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/v1/professional-discussion`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });
      alert('Данные успешно отправлены!');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {' '}
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          
          </div>
        </div>
      </div>
      <div className="prof-discussion">
        <h2 className="prof-discussion__title">
          Профессионально-общественное обсуждение
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="prof-discussion__wrap">
            <InputField
              type="text"
              name="fullname"
              label="Фамилия, Имя, Отчество"
              value={formData.fullname}
              onChange={handleChange('fullname')}
            />
            <InputField
              type="text"
              name="organization"
              label="Организация"
              value={formData.organization}
              onChange={handleChange('organization')}
            />
            <InputField
              type="email"
              name="email"
              label="@mail"
              value={formData.email}
              onChange={handleChange('email')}
            />
            <div className="prof-discussion__first_answer">
              {questions.first_question}
            </div>
            <div className="prof-discussion__checkbox-wrap">
              {answers.map((it, i) => {
                return (
                  <div key={i}>
                    <input
                      className="prof-discussion__checkbox-input"
                      checked={it.checked}
                      type="checkbox"
                      id={`answer-${i}`}
                      onChange={() => handleOnChange(i)}
                    />

                    <label
                      className="prof-discussion__checkbox-label"
                      htmlFor={`answer-${i}`}>
                      <span className="prof-discussion__checkbox-name">
                        {it.answer}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>

            <InputField
              type="text"
              name="second_question"
              label={questions.second_question}
              value={formData.second_answer}
              onChange={handleChange('second_answer')}
            />
            <InputField
              type="text"
              name="third_question"
              label={questions.third_question}
              value={formData.third_answer}
              onChange={handleChange('third_answer')}
            />
            <InputField
              type="text"
              name="fourth_question"
              label={questions.fourth_question}
              value={formData.fourth_answer}
              onChange={handleChange('fourth_answer')}
            />
            <InputField
              type="text"
              name="fifth_question"
              label={questions.fifth_question}
              value={formData.fifth_answer}
              onChange={handleChange('fifth_answer')}
            />
          </div>
          <button className="prof-discussion__btn" disabled={isButtonDisabled}>
            Отправить форму
          </button>
        </form>
      </div>
    </>
  );
};

export default ProfessionalPublicDiscussion;
