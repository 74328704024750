import React from 'react';
import { withRouter } from 'react-router-dom';
import loginLogo from '../../assets/images/png/loginLogo.png';
import validate from '../../helpers/LoginFormValidationRules';
import useForm from '../../helpers/useForm';
import useKeyboardEvent from '../../helpers/useKeyboardEvent';
import './Login.scss';

const LoginFormModal = ({ isShowingLoginForm, toggleCloseLogin, ...props }) => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate,
  );

  async function login() {
    let token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute('content');

    try {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/login_check`, {
        method: 'POST',
        body: `_csrf_token=${token}&_username=${values.email}&_password=${values.password}&_submit=%D0%92%D0%BE%D0%B9%D1%82%D0%B8`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      result && props.history.push('/admin');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  useKeyboardEvent('Escape', () => {
    toggleCloseLogin();
  });

  return isShowingLoginForm ? (
    <>
      <div className="modal-overlay" onClick={toggleCloseLogin} />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog">
        <div className="modal-login">
          <div className="form-container">
            <div className="form-div">
              <div className="form-title">Авторизация</div>
              <div className="form-title-under">
                участников процесса разработки программ ДППО
              </div>
              <div className="form-logo">
                <img src={loginLogo} alt="loginLogo" />
              </div>
              <form method="post" onSubmit={handleSubmit} noValidate>
                <div className="input-container">
                  <label className="form-label" htmlFor="email">
                    Логин:
                  </label>
                  <input
                    className={`form-input ${errors.email && 'is-danger'}`}
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ''}
                    required
                  />
                  {errors.email && <p className="form-error">{errors.email}</p>}
                </div>
                <div className="input-container">
                  <label className="form-label" htmlFor="password">
                    Пароль:
                  </label>
                  <input
                    className={`form-input ${errors.password && 'is-danger'}`}
                    id="password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password || ''}
                    required
                  />
                  {errors.password && (
                    <p className="form-error">{errors.password}</p>
                  )}
                </div>
                <div className="lower-container">
                  <a href="/resetting/request" className="forget-pass">
                    Забыли пароль?
                  </a>
                  <div className="submit-container">
                    <input
                      className="submit"
                      type="submit"
                      name="_submit"
                      value="Войти"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default withRouter(LoginFormModal);
