import React from 'react';
import { useHistory } from 'react-router-dom';
import CoursesLogin from './CoursesLogin';
import './CoursesLogin.scss';

const CoursesLoginFormModal = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const history = useHistory();

  return (
    <CoursesLogin
      isShowingCoursesLoginForm={isOpen}
      toggleCloseCoursesLogin={() => {
        history.goBack();
        setIsOpen(false);
      }}
    />
  );
};

export default CoursesLoginFormModal;
