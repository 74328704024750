import React from "react";
import './Error.scss';

const Error = () => {
    return (
        <div className='Error'>
            <div className="Error__big">
                Произошла ошибка.
            </div>
            <div className="Error__small">
                Повторите попытку позже.
            </div>
        </div>
    )
}

export default Error;
