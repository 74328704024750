import React, { useState, useEffect } from "react";
import "./Registration.scss";

import { useForm } from "react-hook-form";

import CustomSelect from "../UI/CustomSelect/CustomSelect";
import useFormRegistration from "../../helpers/useFormRegistration";
import validate from "../../helpers/RegistrationFormValidationRules";
import useKeyboardEvent from "../../helpers/useKeyboardEvent";

export const LoginFormModal = ({
  isShowingRegistrationForm,
  toggleCloseRegistration,
  toggleOpenConfirm,
  toggleOpenError,
  ...props
}) => {
  const { regions, organizations } = useFormRegistration(login, validate);
  const [selectedRegion, setSelectedRegion] = useState({
    id: 0,
    name: "Все регионы"
  });
  const [selectedOrg, setSelectedOrg] = useState({
    id: 0,
    name: "Все организации"
  });
  const [filteredOrg, setFilteredOrg] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [values, setValues] = useState(null);
  const { register, handleSubmit, errors, setValue } = useForm();
  const onSubmit = async data => {
    try {
      const request = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/reg-author`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
      if (request.ok) {
        login(data)
      } else {
        login({error: true})
      }
      setValues(data);

    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    register({ name: "region" }, { required: true }); // custom register select
    register({ name: "organization" }, { required: true });
  }, [register]);

  async function login(data) {
    try {
      toggleCloseRegistration();
      console.log(data)
      if (data.error) {
        toggleOpenError();
      } else {
        toggleOpenConfirm();
      }
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const filterOrg = () => {
      setFilteredOrg(
        organizations.filter(item => item.region.id === selectedRegion.id)
      );
    };
    filterOrg();
    setSelectedOrg({
      id: 0,
      name: "Все организации"
    });
  }, [selectedRegion, organizations]);

  const handleRegion = newValue => {
    setValue("region", newValue.id);
    setSelectedRegion(newValue);
  };

  const handleOrg = newValue => {
    setValue("organization", newValue.id);
    setSelectedOrg(newValue);
  };

  const checkboxHandler = () => setIsChecked(!isChecked);

  useKeyboardEvent("Escape", () => {
    toggleCloseRegistration();
  });

  return regions && organizations && isShowingRegistrationForm ? (
    <React.Fragment>
      <div className="modal-overlay" onClick={toggleCloseRegistration} />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-registration">
          <div className="form-container-registration">
            <div className="form-div">
              <div className="form-title">Регистрация автора программ ДПО</div>
              <form onSubmit={handleSubmit(onSubmit)} noValidate method="post">
                <div className="input-container">
                  <label className="form-label">Фамилия:</label>
                  <input
                    className={`form-input ${errors.lastName && "is-danger"}`}
                    type="text"
                    name="lastName"
                    ref={register({ required: true, maxLength: 80 })}
                  />
                  {errors.lastName && (
                    <p className="form-error">Фамилия обязательное поле</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Имя:</label>
                  <input
                    className={`form-input ${errors.lastName && "is-danger"}`}
                    type="text"
                    name="firstName"
                    ref={register({ required: true, maxLength: 100 })}
                  />
                  {errors.firstName && (
                    <p className="form-error">Имя обязательное поле</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Отчество:</label>
                  <input
                    className={`form-input ${errors.lastName && "is-danger"}`}
                    type="text"
                    name="patronymic"
                    ref={register({ required: true })}
                  />
                  {errors.patronymic && (
                    <p className="form-error">Отчество обязательное поле</p>
                  )}
                </div>
                <div className="input-container select">
                  <label className="form-label">Регион:</label>
                  <CustomSelect
                    class={`default-regions`}
                    name="region"
                    items={regions}
                    onChange={handleRegion}
                    selectedValue={selectedRegion}
                    zIndex={12}
                  />
                  {errors.region && (
                    <p className="form-error">Регион обязательное поле</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Населенный пункт:</label>
                  <input
                    className={`form-input ${errors.city && "is-danger"}`}
                    type="text"
                    name="city"
                    ref={register({ required: true })}
                  />
                  {errors.city && (
                    <p className="form-error">Город обязательное поле</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Организация:</label>
                  <CustomSelect
                    class={`default-org`}
                    name="organization"
                    items={
                      selectedRegion.id !== 0 ? filteredOrg : organizations
                    }
                    onChange={handleOrg}
                    selectedValue={selectedOrg}
                    zIndex={10}
                    style={{ height: "auto" }}
                  />
                  {errors.organization && (
                    <p className="form-error">Организация обязательное поле</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Должность:</label>
                  <input
                    className={`form-input ${errors.position && "is-danger"}`}
                    type="text"
                    name="position"
                    ref={register({ required: true,
                      maxLength: 250,
                      pattern: {
                        value: /^[A-Za-zА-Яа-яЁё-]+[\sA-Za-zА-Яа-яЁё0-9-.]*$/,
                        message: "Поле Должность - не верные символы!"
                      }
                    })}
                  />
                  {errors.position && (
                    <p className="form-error">Должность обязательное поле</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Телефон:</label>
                  <input
                    className={`form-input ${errors.phone && "is-danger"}`}
                    type="tel"
                    name="phone"
                    ref={register({
                      required: true,
                      maxLength: 12,
                      pattern: /^^((\+7)+([0-9]){10})$/i
                    })}
                    onKeyUp={e => {
                      const value = e.target.value;
                      if (value.match(/^\d{1}$/) !== null) {
                        e.target.value = "+" + value;
                      }
                    }}
                    maxLength="12"
                  />
                  <span className="phone-mask">(в формате +71234567890)</span>
                  {errors.phone && (
                    <p className="form-error">
                      Номер телефона обязательное поле
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Адрес эл. почты:</label>
                  <input
                    className={`form-input ${errors.email && "is-danger"}`}
                    type="text"
                    name="email"
                    ref={register({ required: true, pattern: /\S+@\S+\.\S+/i })}
                  />
                  {errors.email && (
                    <p className="form-error">Не корректно указан адрес электронной почты</p>
                  )}
                </div>
                <div className="input-container" style={{ display: "flex" }}>
                  <input
                    style={{ display: "none" }}
                    type="checkbox"
                    id="agree"
                    name="соглашение"
                    ref={register({ required: true })}
                    checked={isChecked}
                    onChange={checkboxHandler}
                  />
                  <label class="custom-control-label form-label" for="agree">
                    Я принимаю условия{" "}
                    <a href="/termsofuse" target="_blank" className="agree-link">
                      пользовательского соглашения
                    </a>
                  </label>
                </div>
                <div className="lower-container-registration">
                  <div className="submit-container">
                    <input
                      className={`submit-registration ${isChecked === false ? "disable-btn" : ""
                        }`}
                      type="submit"
                      name="_submit"
                      value="Отправить"
                      disabled={isChecked === false}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export const ConfirmModal = ({ isShowingConfirmModal, toggleCloseConfirm, ...props }) => {

  return isShowingConfirmModal ? (
    <React.Fragment>
      <div className="modal-overlay" onClick={toggleCloseConfirm} />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-confirm">
          <div className="form-container">
            <div className="form-div">
              <div className="form-title">Пользователь успешно создан</div>
              <div className="input-container">
                <div className="confirm-label">
                  Логин и пароль пришли на Вашу почту.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export const ErrorModal = ({ isShowingErrorModal, toggleCloseError, ...props }) => {

  return isShowingErrorModal ? (
      <React.Fragment>
        <div className="modal-overlay" onClick={toggleCloseError} />
        <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
        >
          <div className="modal-confirm">
            <div className="form-container">
              <div className="form-div">
                <div className="form-title">Регистрация невозможна</div>
                <div className="input-container">
                  <div className="confirm-label">
                    Пользователь с таким e-mail уже существует!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  ) : null;
};
