import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Button, Checkbox, Input, Select, Tab } from 'semantic-ui-react';
import CheckboxGroup from '../UI/CheckboxGroup/CheckboxGroup';
import CustomTab from '../UI/CustomTab/CustomTab';
import RadioGroup from '../UI/RadioGroup/RadioGroup';
import './ReportPage.scss';

const ReportPage = () => {
  const [values, setValues] = useState({});
  const [activeIndex, setIndex] = useState(0);

  function setValue(field, value, subField = false) {
    if (subField) {
      if (!values[field]) values[field] = {};
      values[field][subField] = value;
    } else values[field] = value;
    setValues(Object.assign({}, values));
  }

  function setCheckBoxValue(field, value, title) {
    if (value) {
      if (!values[field]) values[field] = [title];
      else if (!values[field].includes(title)) values[field].push(title);
    } else {
      if (values[field] && values[field].includes(title)) {
        var index = values[field].indexOf(title);
        values[field].splice(index, 1);
      }
    }
    const new_values = Object.assign({}, values);
    setValues(new_values);
  }

  function sendReport() {
    let fields_list = [
      'subject',
      'name_of_company',
      'professional_program',
      'group_worker',
      'quantity_of_leader',
      'distribution_volume',
      'distribution_by_type_education',
      'stage',
      'network_form',
      'name_of_partner_organizations',
      'individual_study_plan',
      'priority_task_of_state',
      'theme_modules',
      'form_of_organization_classes',
      'form_technologies',
      'other_forms',
      'source_fund_provides',
      'level_of_financial_security',
      'level_stuff',
      'available_logistics',
      'other_education_budget',
      'commercial_organizations',
    ];
    const min_fields = {
      distribution_volume: 4,
      distribution_by_type_education: 5,
    };
    const start_index = activeIndex * 7;
    const end_index = (activeIndex + 1) * 7 + (activeIndex === 2 ? 1 : 0);
    fields_list = fields_list.slice(start_index, end_index);
    let error = false;
    document.querySelectorAll('.error_field').forEach((e) => e.remove());
    fields_list.forEach((field) => {
      let er = false;
      if (min_fields[field]) {
        if (
          typeof values[field] === 'object' &&
          Object.keys(values[field]).length < min_fields[field]
        )
          er = true;
      }
      if (!values[field] || er) {
        var el = document.querySelector('#' + field);
        if (!el) return;
        var elem = document.createElement('div');
        elem.className = 'error_field';
        elem.innerHTML = 'Необходимо заполнить данное поле.';
        el.appendChild(elem);
        error = true;
      }
    });
    if (error) {
      var list = document.querySelectorAll('.error_field');
      list[0].parentElement.scrollIntoView();
      return;
    }
    if (activeIndex < 2) {
      setIndex(activeIndex + 1);
      return;
    }
    const new_values = Object.assign({}, values);
    Object.keys(new_values).forEach((key) => {
      if (Array.isArray(new_values[key])) {
        let t = {};
        new_values[key].forEach((field, index) => {
          t[index] = field;
        });
        new_values[key] = t;
      }
    });
    Object.keys(new_values).forEach((key) => {
      if (typeof new_values[key] === 'object') {
        new_values[key] = JSON.stringify(new_values[key]);
      }
    });
    console.log(new_values);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/report`, new_values)
      .then(function (response) {
        console.log(response.data);
        alert('Анкета отправлена');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /** TODO may be get list of regions via API */
  const subjects_array = [
    'Республика Адыгея (Адыгея)',
    'Республика Алтай',
    'Республика Башкортостан',
    'Республика Бурятия',
    'Республика Дагестан',
    'Республика Ингушетия',
    'Кабардино-Балкарская Республика',
    'Республика Калмыкия',
    'Карачаево-Черкесская Республика',
    'Республика Карелия',
    'Республика Коми',
    'Республика Крым',
    'Республика Марий Эл',
    'Республика Мордовия',
    'Республика Саха (Якутия)',
    'Республика Северная Осетия - Алания',
    'Республика Татарстан (Татарстан)',
    'Республика Тыва',
    'Удмуртская Республика',
    'Республика Хакасия',
    'Чеченская Республика',
    'Чувашская Республика - Чувашия',
    'Алтайский край',
    'Забайкальский край',
    'Камчатский край',
    'Краснодарский край',
    'Красноярский край',
    'Пермский край',
    'Приморский край',
    'Ставропольский край',
    'Хабаровский край',
    'Амурская область',
    'Архангельская область',
    'Астраханская область',
    'Белгородская область',
    'Брянская область',
    'Владимирская область',
    'Волгоградская область',
    'Вологодская область',
    'Воронежская область',
    'Ивановская область',
    'Иркутская область',
    'Калининградская область',
    'Калужская область',
    'Кемеровская область',
    'Кировская область',
    'Костромская область',
    'Курганская область',
    'Курская область',
    'Ленинградская область',
    'Липецкая область',
    'Магаданская область',
    'Московская область',
    'Мурманская область',
    'Нижегородская область',
    'Новгородская область',
    'Новосибирская область',
    'Омская область',
    'Оренбургская область',
    'Орловская область',
    'Пензенская область',
    'Псковская область',
    'Ростовская область',
    'Рязанская область',
    'Самарская область',
    'Саратовская область',
    'Сахалинская область',
    'Свердловская область',
    'Смоленская область',
    'Тамбовская область',
    'Тверская область',
    'Томская область',
    'Тульская область',
    'Тюменская область',
    'Ульяновская область',
    'Челябинская область',
    'Ярославская область',
    'г. Москва',
    'г. Санкт-Петербург',
    'г. Севастополь',
    'Еврейская автономная область',
    'Республика Крым',
    'Ненецкий автономный округ',
    'Ханты-Мансийский автономный округ - Югра',
    'Чукотский автономный округ',
    'Ямало-Ненецкий автономный округ',
  ];
  const panes = [
    {
      menuItem: 'Tab 1',
      render: () => (
        <Tab.Pane>
          <div className="report_field long_input" key="subject" id="subject">
            <h3>Субъект РФ (название полностью)</h3>
            <Select
              options={subjects_array.map((t) => {
                return { text: t, value: t };
              })}
              onChange={(e, data) => {
                setValue('subject', data.value);
              }}
            />
          </div>
          <div
            className="report_field long_input"
            key="name_of_company"
            id="name_of_company">
            <h3>Наименование организации</h3>
            <Input
              onChange={(e) => setValue('name_of_company', e.target.value)}
            />
          </div>
          <div
            className="report_field"
            key="professional_program"
            id="professional_program">
            <h3>
              Какие дополнительные профессиональные программы для руководителей
              организаций общего образования реализуются Вашей организацией?
              (выбрать ВСЕ подходящие варианты ответа)
            </h3>
            <Checkbox
              onChange={(e, data) =>
                setCheckBoxValue(
                  'professional_program',
                  data.checked,
                  data.label,
                )
              }
              label="программы повышения квалификации (ДПП ПК)"
            />
            <br />
            <Checkbox
              onChange={(e, data) =>
                setCheckBoxValue(
                  'professional_program',
                  data.checked,
                  data.label,
                )
              }
              label="программы профессиональной переподготовки (ДПП ПП)"
            />
            <br />
          </div>
          <div className="report_field" key="group_worker" id="group_worker">
            <h3>
              Каким целевым группам педагогических работников адресованы ДПП ПК
              руководителей организаций общего образования (модули ДПП ПК),
              реализуемые Вашей организацией? (выбрать ВСЕ подходящие варианты
              ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'руководители общеобразовательных организаций',
                'руководители дошкольных образовательных организаций',
                'кадровый резерв руководителей организаций общего образования',
                'управленческие команды образовательных организаций общего образования',
                'члены общественных объединений руководителей организаций общего образования',
              ]}
              setValue={(values) => {
                setValue('group_worker', values);
              }}
            />
          </div>
          <div
            className="report_field "
            key="quantity_of_leader"
            id="quantity_of_leader">
            <h3>
              Укажите общее количество ДПП ПК руководителей организаций общего
              образования, реализуемых Вашей организацией в настоящее время:
            </h3>
            <Input
              onChange={(e) => setValue('quantity_of_leader', e.target.value)}
            />
            <span> ед. </span>
          </div>
          <div
            className="report_field"
            key="distribution_volume"
            id="distribution_volume">
            <h3>
              Из них: распределение ДПП ПК руководителей организаций общего
              образования по объему (количеству часов):
            </h3>
            {['0', '1', '2', '3'].map((name) => {
              return (
                <div key={'distribution_volume' + name}>
                  <span className="input_label">
                    {name === '0'
                      ? 'от 16 до 35 часов'
                      : name === '1'
                      ? 'от 36 до 71 часов'
                      : name === '2'
                      ? 'от 72 до 143 часов'
                      : name === '3'
                      ? 'свыше 144 часов'
                      : null}{' '}
                    –{' '}
                  </span>
                  <Input
                    onChange={(e) =>
                      setValue('distribution_volume', e.target.value, name)
                    }
                  />{' '}
                  <span>ед. </span>
                </div>
              );
            })}
          </div>
          <div
            className="report_field"
            key="distribution_by_type_education"
            id="distribution_by_type_education">
            <h3>
              Из них: распределение ДПП ПК руководителей организаций общего
              образования по формам обучения:
            </h3>
            {['0', '1', '2', '3', '4', '5'].map((name) => {
              return (
                <div key={'distribution_by_type_education' + name}>
                  <span className="input_label">
                    {name === '0'
                      ? 'очная'
                      : name === '1'
                      ? 'очно-заочная'
                      : name === '2'
                      ? 'заочная'
                      : name === '3'
                      ? 'очно-заочная с применением дистанционных образовательных технологий и электронного обучения'
                      : name === '4'
                      ? 'заочная с применением дистанционных образовательных технологий и электронного обучения'
                      : name === '5'
                      ? 'иное (что именно)'
                      : null}{' '}
                    –{' '}
                  </span>
                  <Input
                    onChange={(e) =>
                      setValue(
                        'distribution_by_type_education',
                        e.target.value,
                        name,
                      )
                    }
                  />{' '}
                  <span>ед. </span>
                </div>
              );
            })}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Tab 2',
      render: () => (
        <Tab.Pane>
          <div className="report_field" key="stage" id="stage">
            <h3>
              Используются ли стажировки, обмен опытом при реализации ДПП ПК
              руководителей организаций общего образования Вашей организацией?
              (выбрать ОДИН подходящий вариант ответа)
            </h3>
            <RadioGroup
              fields={['да, регулярно', 'да, иногда', 'нет, не используются']}
              setValue={(value) => setValue('stage', value)}
              custom_last="иное (что именно)"
            />
          </div>
          <div className="report_field" key="network_form" id="network_form">
            <h3>
              Используется ли Вашей организацией сетевая форма реализации ДПП ПК
              руководителей организаций общего образования? (выбрать ОДИН
              подходящий вариант ответа)
            </h3>
            <RadioGroup
              fields={['да', 'нет', 'затрудняюсь ответить']}
              setValue={(value) => setValue('network_form', value)}
            />
          </div>
          <div
            className="report_field long_input"
            key="name_of_partner_organizations"
            id="name_of_partner_organizations">
            <h3>
              Укажите наименования организаций-партнеров по реализации в сетевой
              форме ДПП ПК руководителей организаций общего образования:
            </h3>
            <RadioGroup
              fields={['затрудняюсь ответить', 'сетевая форма не используется']}
              setValue={(value) =>
                setValue('name_of_partner_organizations', value)
              }
              custom_first=""
            />
          </div>
          <div
            className="report_field"
            key="individual_study_plan"
            id="individual_study_plan">
            <h3>
              Реализуются ли в Вашей организации индивидуальные учебные планы
              (образовательные маршруты) повышения квалификации руководителей
              организаций общего образования? (выбрать ОДИН подходящий вариант
              ответа)
            </h3>
            <RadioGroup
              fields={['да', 'нет', 'затрудняюсь ответить']}
              setValue={(value) => setValue('individual_study_plan', value)}
            />
          </div>
          <div
            className="report_field"
            key="priority_task_of_state"
            id="priority_task_of_state">
            <h3>
              На решение каких приоритетных задач государственной политики в
              области образования нацелены ДПП ПК руководителей организаций
              общего образования, реализуемые Вашей организацией? (выбрать ВСЕ
              подходящие варианты ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'внедрение в общеобразовательных организациях новых методов обучения и воспитания, образовательных технологий, обеспечивающих освоение обучающимися базовых навыков и умений',
                'повышение качества общего образования, оценка качества с использованием инструментария международных сравнительных исследований',
                'воспитание гармонично развитой и социально ответственной личности на основе духовно-нравственных ценностей народов Российской федерации',
                'формирование эффективной системы выявления, поддержки и развития способностей и талантов у детей и молодёжи',
                'создание системы самоопределения и профессиональной ориентации обучающихся',
                'создание современной и безопасной цифровой образовательной среды',
                'внедрение национальной системы профессионального роста педагогических работников',
                'создание условий для развития наставничества',
                'поддержка общественных инициатив и проектов, в том числе в сфере добровольчества (волонтёрства)',
              ]}
              setValue={(values) => {
                setValue('priority_task_of_state', values);
              }}
              label="иное (что именно)"
            />
          </div>
          <div className="report_field" key="theme_modules" id="theme_modules">
            <h3>
              Какие из перечисленных ниже элементов содержания представлены в
              модулях (темах) ДПП ПК руководителей организаций общего
              образования, реализуемых Вашей организацией? (выбрать ВСЕ
              подходящие варианты ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'государственная политика в сфере образования',
                'национальный проект «Образование»',
                'задачи реализация федерального проекта национального проекта «Образование» в образовательной организации  (указать, какого именно: )',
                'внедрение современных технологий (методов) обучения и воспитания',
                'управление развитием образовательной организации. Программа развития образовательной организации',
                'цифровая образовательная среда в организациях общего образования',
                'организация инклюзивного образования',
                'сетевые формы реализации образовательных программ в общем образовании',
                'современные внутришкольные системы оценки качества образования',
                'организация профориентационной работы в школе',
                'реализация индивидуальных образовательных маршрутов в школе',
                'новые задачи и формы организация воспитательного процесса в школе',
                'современные подходы к организации профильного обучения',
                'обеспечение профессионального развития педагогических работников в образовательных организациях',
              ]}
              setValue={(values) => {
                setValue('theme_modules', values);
              }}
              label="иное (что именно)"
            />
          </div>
          <div
            className="report_field"
            key="form_of_organization_classes"
            id="form_of_organization_classes">
            <h3>
              Какие формы организации занятий с применением дистанционных
              образовательных технологий и электронного обучения используются
              Вашей организацией при реализации ДПП ПК руководителей организаций
              общего образования? (выбрать ВСЕ подходящие варианты ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'on-line семинары, вебинары',
                'интерактивные занятия с применением видеоконференцсвязи',
                'занятия, включающие самостоятельную и групповую on-line работу с сетевыми образовательными ресурсами (образовательные платформы, порталы, тематических коллекций цифровых образовательных ресурсов)',
                'самостоятельные занятия с использованием электронных образовательных ресурсов (электронных учебно-методических комплексов и демонстрационных материалов, дистанционные лекции, лабораторные работы, практикумы и др.)',
                'проведение занятий в учебно-тренировочных классах (компьютерных практикумов, тренажеров, имитационного моделирования, деловых игр и т.д.)',
                'входной, промежуточный, итоговый контроль знаний, диагностика компетенций с применением электронных средств и технологий',
              ]}
              setValue={(values) => {
                setValue('form_of_organization_classes', values);
              }}
              label="иное (что именно)"
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Tab 3',
      render: () => (
        <Tab.Pane>
          <div
            className="report_field"
            key="form_technologies"
            id="form_technologies">
            <h3>
              Какие формы и технологии проведения очных практических занятий при
              реализации ДПП ПК руководителей организаций общего образования
              используются Вашей организацией? (выбрать ВСЕ подходящие варианты
              ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'семинары',
                'мастер-классы',
                'круглый стол',
                'тренинги навыков, умений',
                'форсайт-сессия (расстановка приоритетов в реализации проектов)',
                'работа в малых группах (мозговой штурм, анализ конкретных ситуаций, проблемный анализ, проектирование и др.)',
                'защита проектов',
                'обучающие игры (ролевые, имитационные, деловые, образовательные и др.)',
                '«дебаты»',
                'групповые дискуссии',
              ]}
              setValue={(values) => {
                setValue('form_technologies', values);
              }}
            />
          </div>
          <div className="report_field" key="other_forms" id="other_forms">
            <h3>
              Какие ИНЫЕ формы использует Ваша организация в целях
              профессионального развития руководителей организаций общего
              образования? (выбрать ВСЕ подходящие варианты ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'организация проблемных (тематических) семинаров, конференций, чтений и т. п.',
                'привлечение руководителей ОО к реализации инновационных проектов, программ',
                'подготовка и проведение конкурсов профессионального мастерства',
                'образовательная и методическая поддержка прохождения аттестации',
                'привлечение руководителей ОО к экспертной деятельности',
                'привлечение руководителей ОО к реализации ДПП ПК (представление опыта)',
                'привлечение руководителей ОО в программы наставничества',
                'организация региональных и межрегиональных программ обмена опытом',
                'создание и поддержка сообществ, ассоциаций (в т. ч. сетевых) для руководителей организаций общего образования',
              ]}
              setValue={(values) => {
                setValue('other_forms', values);
              }}
            />
          </div>
          <div
            className="report_field"
            key="source_fund_provides"
            id="source_fund_provides">
            <h3>
              Из каких источников обеспечивается финансирование ДПП ПК
              руководителей организаций общего образования, реализуемых Вашей
              организацией? (выбрать ВСЕ подходящие варианты ответа)
            </h3>
            <CheckboxGroup
              fields={[
                'государственное задание',
                'средства от участия в реализации инновационных проектов, программ субъекта РФ',
                'средства от участия в реализации инновационных проектов, программ федерального уровня ',
                'средства от участия в реализации инновационных проектов, программ различных фондов, коммерческих организаций',
                'средства грантов',
                'внебюджетные поступления (средства от реализации образовательных услуг)',
                'доходы от иной деятельности организации',
              ]}
              setValue={(values) => {
                setValue('source_fund_provides', values);
              }}
            />
          </div>
          <div
            className="report_field"
            key="level_of_financial_security"
            id="level_of_financial_security">
            <h3>
              Как вы оцениваете уровень финансового обеспечения реализации ДПП
              ПК руководителей организаций общего образования с учетом
              актуальных задач государственной политики? (выбрать ОДИН
              подходящий вариант ответа)
            </h3>
            <RadioGroup
              fields={[
                'высокий уровень финансового обеспечения',
                'достаточный уровень финансового обеспечения',
                'недостаточный уровень финансового обеспечения',
                'критически низкий уровень финансового обеспечения',
                'затрудняюсь ответить',
              ]}
              setValue={(value) =>
                setValue('level_of_financial_security', value)
              }
            />
          </div>
          <div className="report_field" key="level_stuff" id="level_stuff">
            <h3>
              Как Вы оцениваете уровень кадровой обеспеченности ДПП ПК
              руководителей организаций общего образования с учетом актуальных
              задач государственной политики? (выбрать ОДИН подходящий вариант
              ответа)
            </h3>
            <RadioGroup
              fields={[
                'высокий уровень кадровой обеспеченности',
                'достаточный уровень кадровой обеспеченности',
                'недостаточный уровень кадровой обеспеченности',
                'критически низкий уровень кадровой обеспеченности',
                'затрудняюсь ответить',
              ]}
              setValue={(value) => setValue('level_stuff', value)}
            />
          </div>
          <div
            className="report_field"
            key="available_logistics"
            id="available_logistics">
            <h3>
              Укажите наличие в Вашей организации материально-технических
              условий реализации ДПП ПК руководителей организаций общего
              образования с учетом новых запросов к системе ДПО? (выбрать ВСЕ
              подходящие варианты ответа):
            </h3>
            <CheckboxGroup
              fields={[
                'специально оборудованные аудитории для лекций, публичных выступлений',
                'специально оборудованные аудитории для лабораторных и практических занятий',
                'высокотехнологичное оборудование для создания имитационных площадок',
                'цифровые лаборатории и вспомогательное оборудование для проведения экспериментов',
                'аудиовизуальные, технические и компьютерные средства обучения ',
                'мультимедийное, проекционное оборудование ',
                'достаточное для слушателей количество персональных компьютеров, мобильной компьютерной техники',
                'достаточное количество рабочих мест для слушателей с выходом в Интернет',
                'высокоскоростной доступ к сети Интернет',
              ]}
              setValue={(values) => {
                setValue('available_logistics', values);
              }}
            />
          </div>
          <div
            className="report_field long_input"
            key="other_education_budget"
            id="other_education_budget">
            <h3>
              Какие еще бюджетные образовательные организации в Вашем субъекте
              РФ предлагают ДПП для руководителей организаций общего
              образования? (перечислите несколько известных Вам)
            </h3>
            <RadioGroup
              fields={['затрудняюсь ответить']}
              setValue={(value) => setValue('other_education_budget', value)}
              custom_first=""
            />
          </div>
          <div
            className="report_field long_input"
            key="commercial_organizations"
            id="commercial_organizations">
            <h3>
              Какие коммерческие организации предлагают ДПП для руководителей
              организаций общего образования в Вашем субъекте РФ? (перечислите
              несколько известных Вам)
            </h3>
            <RadioGroup
              fields={['затрудняюсь ответить']}
              setValue={(value) => setValue('commercial_organizations', value)}
              custom_first=""
            />
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div className="ReportPage__container">
        <div className="ReportPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="ReportPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="ReportPage__breadcrumbs__slash" />
            <Link className="ReportPage__breadcrumbs__second-link">Анкета</Link>
          </div>
          <div />
        </div>
        <div className="ReportPage">
          <br />
          <div className="ReportPageTabs">
            <div>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}>
                Уважаемые коллеги!
              </p>
              <div style={{ fontSize: '16px' }}>
                При работе с анкетой просим отвечать на все вопросы без
                пропусков. При выборе ответов обращайте внимание на указания о
                возможном количестве ответов (в скобках). В каждом вопросе
                выберите подходящий вариант (или варианты) ответа и отметьте его
                в соответствующей строке электронного опросника.
              </div>
              <br />
            </div>
            <CustomTab panes={panes} activeIndex={activeIndex} />
            <br />
            <div>
              {activeIndex > 0 && (
                <Button onClick={() => setIndex(activeIndex - 1)}>Назад</Button>
              )}
              <Button onClick={() => sendReport()}>
                {activeIndex === 2 ? 'Отправить' : 'Продолжить'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPage;
