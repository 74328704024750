import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import extentionChecker from '../../../helpers/extentionChecker';
import './FullBankProgramm.scss';

const FullBankProgramm = (props) => {
  const [cardData, setCardData] = useState(null);

  useEffect(() => {
    async function fechBankData() {
      const { id } = props.match.params;
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/programs/${id}`);
      setCardData(result.data);
    }
    fechBankData();
  }, [props.match.params]);
  
  return (
    <div>
      <div className="FullProgramm__container">
        <div className="fade-animation">
          <div className="AboutPage__breadcrumbs">
            <div />
            <div className="bc-width">
              <Link to="/" className="FullProgramm__breadcrumbs__main-link">
                Главная
              </Link>
              <div className="FullProgramm__breadcrumbs__slash" />
              <Link
                to={`/bank/?page=1`}
                className="FullProgramm__breadcrumbs__main-link">
                Лучшие программы ДПО
              </Link>
              <div className="FullProgramm__breadcrumbs__slash" />
              <div className="FullProgramm__breadcrumbs__second-link">
                {cardData && cardData.education_program}
              </div>
            </div>
            <div />
          </div>
          <div className="FullProgramm">
            <div />
            {cardData && (
              <div>
                <div className="FullProgramm__title">
                  {cardData && cardData.education_program}
                </div>
                <div></div>
                <div className="FullProgramm__content">
                  <div className="FullProgramm__content__item-wrapper padding border-top">
                    <div className="FullProgramm__content__item-category">
                      Наименование образовательной организации ДПО
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {cardData.education_organisation}
                    </div>
                  </div>
                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Наименование электронной образовательной программы (далее
                      - ЭОП)
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {cardData.education_program}
                    </div>
                  </div>
                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Краткая аннотация ЭОП
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {cardData.annotation}
                    </div>
                  </div>
                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Платформа, на которой реализуется ЭОП
                    </div>
                    <div className="FullProgramm__content__item-data">
                      <a href={cardData.link}>{cardData.link}</a>
                    </div>
                  </div>
                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Авторы и соавторы ЭОП
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {cardData.author}
                    </div>
                  </div>
                  {/*<div className="FullProgramm__content__item-wrapper">*/}
                  {/*  <div className="FullProgramm__content__item-category">*/}
                  {/*    Федеральные эксперты*/}
                  {/*  </div>*/}
                  {/*  <div className="FullProgramm__content__item-data">*/}
                  {/*    {cardData.experts}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Дата разработки
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {moment(cardData.published_at).format('D MMMM YYYY')}
                    </div>
                  </div>

                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Дата размещения в реестре
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {cardData.reestrDate &&
                        moment(cardData.reestrDate).format('D MMMM YYYY')}
                    </div>
                  </div>

                  <div className="FullProgramm__content__item-wrapper">
                    <div className="FullProgramm__content__item-category">
                      Сроки реализации программы
                    </div>
                    <div className="FullProgramm__content__item-data">
                      {cardData.periodOfImplementation}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '15px',
                      justifyContent: 'flex-end',
                    }}>
                    <div className="link-container">
                      {cardData && cardData.link &&
                        <a
                          className={`link-agree`}
                          href={cardData.link}
                          target={'_blank'}
                          rel="noopener noreferrer">
                          Записаться
                        </a>
                      }
                    </div>
                    <div
                      className="BankCard__download-wrapper"
                      style={{ justifyContent: 'flex-end' }}>
                      <span
                        className="BankCard__download-text"
                        style={{ marginRight: '5px' }}>
                        Загрузить
                      </span>
                      <span className="BankCard__pdf">
                        {cardData.file &&
                          cardData.file.map((item, id) => (
                            <a
                              key={id}
                              href={`${process.env.REACT_APP_API_URL}/uploads/${item.dir}/${item.filename}`}
                              download={item.realname}>
                              {extentionChecker(item)}
                            </a>
                          ))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FullBankProgramm);
