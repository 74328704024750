import React from 'react';
import logo from '../../assets/images/png/logo.png';
import useFilterHook from '../../helpers/SortingHook';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import Pagination from '../UI/Pagination/Pagination';
import './CatalogPage.scss';

const CatalogPage = () => {
  const {
    currentCards,
    searchFilter,
    handleInputSelect,
    search,
    handleInputSearch,
    selectedCategoryTab,
    handleCategoryTabChange,
    selectedSubjectTab,
    handleSubjectTabChange,
    handleSortingTab,
    SortingAsc,
    selectedSortingTab,
    selectedInputSelect,
    onPageChanged,
    currentCardsPagination,
  } = useFilterHook(items);

  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div className="CatalogPage__container">
        <div className="CatalogPage">
          <div />
          <div>
            <div className="CatalogPage__title">Каталог курсов</div>
            <div className="CatalogPage__upper-filter">
              <div className="CatalogPage__search-container">
                <div className="CatalogPage__search-select">
                  <CustomSelect
                    class="InputSelect"
                    name="select"
                    items={inputSelect}
                    onChange={handleInputSelect}
                    selectedValue={selectedInputSelect}
                  />
                </div>
                <input
                  type="text"
                  className="CatalogPage__search-select__input"
                  placeholder="Поисковое слово или запрос"
                  value={search}
                  onChange={handleInputSearch}
                />
              </div>
            </div>
            <div className="CatalogPage__lower-filter">
              <CustomSelect
                class="CircleSelect"
                name="selectedCategoryTab"
                items={categoryItems}
                onChange={handleCategoryTabChange}
                selectedValue={selectedCategoryTab}
              />
              <CustomSelect
                class="CircleSelect"
                name="selectedSubjectTab"
                items={subjectItems}
                onChange={handleSubjectTabChange}
                selectedValue={selectedSubjectTab}
              />
              <div className="CatalogPage__sort-container">
                Cортировать:
                <div className={`CatalogPage__arrow`} onClick={SortingAsc} />
                <span className="CatalogPage__sort-category">
                  <CustomSelect
                    class="AlphabetSelect"
                    name="select"
                    items={sortItems}
                    onChange={handleSortingTab}
                    selectedValue={selectedSortingTab}
                  />
                </span>
              </div>
            </div>
            <div style={{ marginTop: '100px' }}>
              {currentCardsPagination &&
                currentCardsPagination.map((item) => (
                  <CatalogPageCard
                    key={item.id}
                    title={item.title}
                    text={item.text}
                    location={item.location}
                  />
                ))}
              <Pagination
                totalRecords={currentCards.length}
                pageLimit={6}
                pageNeighbors={1}
                onPageChanged={onPageChanged}
              />
            </div>
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

const CatalogPageCard = ({ title, text, location }) => {
  return (
    <div className="CatalogPageCard">
      <div className="CatalogPageCard__title">{title}</div>
      <div className="CatalogPageCard__desc">{text}</div>
      <div className="CatalogPageCard__lower-container">
        <div>
          <span className="CatalogPageCard__org">Организатор: </span>
          <span className="CatalogPageCard__org-name">
            МЦРКПО. Отдел инклюзивного образования
          </span>
          <span className="CatalogPageCard__org-location">{location}</span>
        </div>
        <div>
          <span className="CatalogPageCard__format">Формат обучения: </span>
          <span className="CatalogPageCard__format-name">
            Дистанционно с автоматической проверкой
          </span>
        </div>
        <div>
          <span className="CatalogPageCard__time-name">Часов:</span>
          <span className="CatalogPageCard__time">18</span>
        </div>
        <div style={{ justifySelf: 'end' }}>
          <button className="CatalogPageCard__btn">Перейти</button>
        </div>
      </div>
    </div>
  );
};

export default CatalogPage;

const items = [
  {
    title:
      'проектирование и разработка специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'z индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 1,
    category_id: 1,
    subject_id: 4,
    input_id: 21,
  },
  {
    title:
      'разработка специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'f профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Астраханская область',
    subject: 'Математика',
    id: 21,
    category_id: 2,
    subject_id: 5,
    input_id: 2,
  },
  {
    title:
      'специальной индивидуальной программы развития для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'l для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы',
    location: 'Хабаровская область',
    subject: 'Математика',
    id: 43,
    category_id: 1,
    subject_id: 5,
    input_id: 43,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 2,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 26,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 82,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 29,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 20,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'a разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 34,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
  {
    title: 'для обучающихся с тяжелыми множественными нарушениями развития',
    text:
      'Последняя разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития (ТМНР). Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы развития для обучающихся с тяжелыми и множественными нарушения развития. Совершенствование профессиональных компетенций в области проектирования и разработки специальной индивидуальной программы Саратовская',
    location: 'Саратовская область',
    subject: 'География',
    id: 99,
    category_id: 1,
    subject_id: 4,
    input_id: 2,
  },
];

const sortItems = [
  { value: 'по алфавиту', id: 1 },
  { value: 'по субъекту РФ', id: 2 },
  { value: 'по автору', id: 3 },
  { value: 'по предмету', id: 4 },
  { value: 'по времени обучения', id: 5 },
];

const inputSelect = [
  { value: 'По содержанию', id: 0 },
  { value: 'Гуманитарное образование Естественнонаучное образование', id: 1 },
  { value: 'Математика', id: 21 },
  { value: 'Программирование и ИКТ', id: 43 },
  { value: 'Начальное образование', id: 2 },
];

const categoryItems = [
  { id: 0, value: 'Все категории' },
  { id: 1, value: 'Повышение квалификации' },
  { id: 2, value: 'Переподготовка' },
];

const subjectItems = [
  { id: 0, value: 'Все предметы' },
  { id: 4, value: 'География' },
  { id: 5, value: 'Математика' },
];
