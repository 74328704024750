import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import validate from '../../helpers/LoginFormValidationRules';
import useForm from '../../helpers/useForm';
import useKeyboardEvent from '../../helpers/useKeyboardEvent';
import Modal from '../UI/Modal/Modal';
import './CoursesLogin.scss';
import loginLogo from '../../assets/images/png/loginLogo.png';


const CoursesLoginFormModal = ({
  isShowingCoursesLoginForm,
  toggleCloseCoursesLogin,
  ...props
}) => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate,
  );
  const [error, setError] = useState(null);

  const onDialogClick = function (event) {
    event.stopPropagation();
  };

  async function login() {
    try {
      const result = await fetch(
        //`https://e-learning.dppo.edu.ru/login/index.php`,
        `${process.env.REACT_APP_ELEARNING_DPPO_LOGIN}`,
        {
          method: 'POST',
          body: `username=${values.email}&password=${values.password}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      //result && window.open('https://e-learning.dppo.edu.ru/');
      result && window.open(`${process.env.REACT_APP_ELEARNING_DPPO}`);
    } catch (error) {
      console.log(error);
    }
  }

  useKeyboardEvent('Escape', () => {
    toggleCloseCoursesLogin();
  });

  useEffect(() => {
    let link = queryString.parse(props.history.location.search);

    if (link === undefined || link.errorcode === undefined) {
      setError(null);
      return;
    }

    setError(link.errorcode);
  }, [props.history.location.search]);

  const form_action_login = process.env.REACT_APP_ELEARNING_DPPO_LOGIN;
  const forgot_password   = process.env.REACT_APP_ELEARNING_DPPO_FORGOT_PASSWORD;

  return (
    <Modal
      toggleBack={toggleCloseCoursesLogin}
      isShowing={isShowingCoursesLoginForm}>
      <div className="modal-courses-login" onClick={onDialogClick}>
        <div className="form-container">
          <div className="form-div">
            <div className="form-title">Авторизация</div>
            <div className="form-title-under">
              на курсы повышения квалификации
            </div>
            <div className="form-logo">
              <img src={loginLogo} alt="loginLogo" />
            </div>
            <form
              method="post"
              //action="https://e-learning.dppo.edu.ru/login/index.php"
              action={form_action_login}
            >
              <div className="input-container">
                <label className="form-label" htmlFor="username">
                  Логин:
                </label>
                <input
                  className={`form-input ${errors.username && 'is-danger'}`}
                  id="username"
                  type="username"
                  name="username"
                  onChange={handleChange}
                  value={values.username || ''}
                  required
                />
                {errors.username && (
                  <p className="form-error">{errors.username}</p>
                )}
              </div>
              <div className="input-container">
                <label className="form-label" htmlFor="password">
                  Пароль:
                </label>
                <input
                  className={`form-input ${errors.password && 'is-danger'}`}
                  id="password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password || ''}
                  required
                />
                {errors.password && (
                  <p className="form-error">{errors.password}</p>
                )}
              </div>
              <div className="lower-container">
                <a
                  //href="https://e-learning.dppo.edu.ru/login/forgot_password.php"
                  href={forgot_password}
                  className="forget-pass">
                  Забыли пароль?
                </a>
                <div className="submit-container">
                  <input
                    className={`submit`}
                    type="submit"
                    name="_submit"
                    value="Войти"
                  />
                </div>
              </div>
            </form>
            {error && error === '1' && (
              <div className="login-error">
                <span>Пользователь не найден</span>
              </div>
            )}
            {error && error === '2' && (
              <div className="login-error">
                <span>Пользователь заблокирован</span>
              </div>
            )}
            {error && error === '3' && (
              <div className="login-error">
                <span>Неверный логин или пароль</span>
              </div>
            )}
            {error && error === '4' && (
              <div className="login-error">
                <span>Нет доступа</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(CoursesLoginFormModal);
