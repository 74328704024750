import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card } from './Card';
import './News.scss';

export const News = () => {
  const [link, setLink] = useState();
  const [items, setItems] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await axios(`${process.env.REACT_APP_API_URL}/api/v1/latest-news`);
      setItems(data.lastedItems);
      setLink(data.linkFullNews);
    })();
  }, []);

  return (
    <div className="News">
      <h1 className="News__header">Новости</h1>
      <div className="News__content">
        {items &&
          items.map((item) => {
            return <Card key={item.id} item={item} />;
          })}
      </div>
      <div className="News__btn-container">
        <button
          onClick={() => {
            window.location = link;
          }}
          className="News__btn">
          Больше новостей
        </button>
      </div>
    </div>
  );
};
