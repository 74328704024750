import React from 'react';
import loginPeople from '../../../assets/images/png/loginPeople.png';
import { Link } from 'react-router-dom';

const Button = (className, context, link) => (
  <Link to={link}>
    <button className={className}>
      <img src={loginPeople} alt="loginPeople" />
      {context}
    </button>
  </Link>
);

export const LinkButton = (className, context, link) => (
  <a href={link}>
    <button className={className}>{context}</button>
  </a>
);

export default Button;
