import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import './Registration.scss';

export const RegistrationDPO = ({
  isShowingRegistrationDPOForm,
  toggleCloseRegistrationDPO,
  toggleOpenConfirmDPO,
  toggleOpenErrorDPO,
  ...props
}) => {
  const { register, handleSubmit, errors } = useForm();
  const formRef = useRef(null);
  const [fileName, setFileName] = useState('Лицензия в формате .pdf');
  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = async () => {
    const formData = new FormData(formRef.current);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/v1/reg-org`, {
        method: 'POST',
        body: formData,
      });
      window.scroll(0, 0);
      toggleCloseRegistrationDPO();
      toggleOpenConfirmDPO();
    } catch (err) {
      toggleCloseRegistrationDPO();
      toggleOpenErrorDPO();
    }
  };

  const checkboxHandler = () => setIsChecked(!isChecked);

  return isShowingRegistrationDPOForm ? (
    <React.Fragment>
      <div className="modal-overlay" onClick={toggleCloseRegistrationDPO} />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog">
        <div className="modal-registration">
          <div className="form-container-registration">
            <div className="form-div">
              <div className="form-title">РЕГИСТРАЦИЯ ОРГАНИЗАЦИЙ, РЕАЛИЗУЮЩИХ ДПП</div>

              <p className="form-description">
                Для регистрации организации на Портале ДПО необходимо
                заполнить следующую форму. Ответственный от организации
                выполняет функции оператора и активирует авторов своей
                организации.
              </p>

              <p className="form-message">
              </p>

              <form
                onSubmit={handleSubmit(onSubmit)}
                ref={formRef}
                method="post">
                <div className="input-container">
                  <label className="form-label">
                    Полное наименование организации согласно уставу:
                  </label>
                  <input
                    className={`form-input ${
                      errors.fullNameOrganization && 'is-danger'
                    }`}
                    type="text"
                    name="fullNameOrganization"
                    ref={register({ required: true, maxLength: 500 })}
                  />
                  {errors.fullNameOrganization && (
                    <p className="form-error">
                      Полное наименование учреждения обязательное поле
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">
                    Краткое наименование организации согласно уставу:
                  </label>
                  <input
                    className={`form-input ${
                      errors.shortNameOrganization && 'is-danger'
                    }`}
                    type="text"
                    name="shortNameOrganization"
                    ref={register({ required: true, maxLength: 250 })}
                  />
                  {errors.shortNameOrganization && (
                    <p className="form-error">
                      Краткое наименование учреждения обязательное поле
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">
                    ФИО ответственного лица (оператор организации):
                  </label>
                  <input
                    className={`form-input ${
                      errors.fioRegionalOperator && 'is-danger'
                    }`}
                    type="text"
                    name="fioRegionalOperator"
                    ref={register({ required: true, maxLength: 80 })}
                  />
                  {errors.fioRegionalOperator && (
                    <p className="form-error">
                      ФИО ответственного лица обязательное поле
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label className="form-label">Номер телефона:</label>
                  <input
                    className={`form-input ${errors.phone && 'is-danger'}`}
                    type="tel"
                    name="phone"
                    ref={register({
                      required: true,
                      maxLength: 12,
                      pattern: /^^((\+7)+([0-9]){10})$/i,
                    })}
                    onKeyUp={(e) => {
                      const value = e.target.value;
                      if (value.match(/^\d{1}$/) !== null) {
                        e.target.value = '+' + value;
                      }
                    }}
                    maxLength="12"
                  />
                  <span className="phone-mask">(в формате +71234567890)</span>
                  {errors.phone && (
                    <p className="form-error">Номер телефона не корректен</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label">Адрес электронной почты:</label>
                  <input
                    className={`form-input ${errors.email && 'is-danger'}`}
                    type="email"
                    name="email"
                    ref={register({ required: true, pattern: /\S+@\S+\.\S+/i })}
                  />
                  {errors.email && (
                    <p className="form-error">Эл. почта не корректна</p>
                  )}
                </div>
                <div className="input-container">
                  <label className="form-label ">
                    Прикрепить образовательную лицензию:
                  </label>
                  <div className="form-file-input-box">
                    <div className="form-file-input-filename">{fileName}</div>
                    <label className="custom-file-upload" htmlFor={'fileInput'}>
                      <input
                        className={`form-input ${
                          errors.licenceFile && 'is-danger'
                        }`}
                        type="file"
                        id="fileInput"
                        name="licenceFile"
                        accept="application/pdf"
                        onChange={(e) => setFileName(e.target.files[0].name)}
                        ref={register({ required: true })}
                      />
                      Обзор
                    </label>
                  </div>
                  {errors.licenceFile && (
                    <p className="form-error">Файл обязателен</p>
                  )}
                </div>
                <div className="input-container" style={{ display: 'flex' }}>
                  <input
                    style={{ display: 'none' }}
                    type="checkbox"
                    id="agree"
                    checked={isChecked}
                    onChange={checkboxHandler}
                  />
                  <label
                    className="custom-control-label form-label"
                    htmlFor="agree">
                    Я принимаю условия{' '}
                    <a
                      href="/termsofuse"
                      target="_blank"
                      className="agree-link">
                      пользовательского соглашения
                    </a>
                  </label>
                </div>
                <div className="lower-container-registration">
                  <div className="submit-container">
                    <input
                      className={`submit-registration ${
                        isChecked === false ? 'disable-btn' : ''
                      }`}
                      type="submit"
                      name="_submit"
                      value="Отправить"
                      disabled={isChecked === false}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export const ConfirmModalDPO = ({
  isShowingConfirmDPO,
  toggleCloseConfirmDPO,
  ...props
}) => {
  return isShowingConfirmDPO ? (
    <React.Fragment>
      <div className="modal-overlay" onClick={toggleCloseConfirmDPO} />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog">
        <div className="modal-confirm">
          <div className="form-container">
            <div className="form-div">
              <div className="input-container" style={{ marginBottom: '0' }}>
                <div
                  className="confirm-label"
                  style={{ fontSize: '25px', marginBottom: '0' }}>
                  Данные успешно отправлены
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export const ErrorModalDPO = ({
  isShowingErrorDPO,
  toggleCloseErrorDPO,
  ...props
}) => {
  return isShowingErrorDPO ? (
    <React.Fragment>
      <div className="modal-overlay" onClick={toggleCloseErrorDPO} />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog">
        <div className="modal-confirm">
          <div className="form-container">
            <div className="form-div">
              <div className="input-container" style={{ marginBottom: '0' }}>
                <div
                  className="confirm-label"
                  style={{ fontSize: '25px', marginBottom: '0' }}>
                  Произошла ошибка. Попробуйте позже!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};
