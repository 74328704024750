import React, {useEffect, useState} from "react";
import "./SimilarArticles.scss";
import Slider from '../UI/Slider/Slider';
import useFetch from '../../helpers/useFetch';
const SimilarArticles = ({currentArticleId}) => {

    const [result, , getRequest] = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/journal/vektor/carousel/${currentArticleId}`);
    const [similarArticles, setSimilarArticles] = useState([]);


    useEffect(()=>{
        getRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if (result.length) {
            setSimilarArticles(result.slice(0,3))
        }
    },[result])

    return (
        <>
            <div className='SimilarArticles'>
                <div className="SimilarArticles__caption">
                    Вам может быть интересно:
                </div>
                <div className="SimilarArticles__list">
                        {similarArticles && similarArticles.length &&
                            <Slider items={similarArticles}/>
                       }
                </div>
            </div>
        </>
    );
};

export default SimilarArticles;
