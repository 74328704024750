import React, { useState, useRef, useEffect } from "react";
import "./CustomSelect.scss";

const CustomSelect = ({ items, ...props }) => {
  const node = useRef();
  const [selectedItem, selectItem] = useState(items[0]);
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  function handleClick(e) {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsShowing(false);
  }

  function selectItemHandler(item) {
    selectItem(item);
    props.onChange(item);
    setIsShowing(!isShowing);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    selectItem(props.selectedValue);
  }, [props.selectedValue]);

  return (
    <>
      <div ref={node} className={props.class}>
        <div
          className={`${props.class}__container`}
          style={{ zIndex: props?.zIndex }}
          onClick={toggle}
        >
          <div className={`${props.class}__selected-item`}>
            {selectedItem &&
              (selectedItem.name || props.selectedValue.name) &&
              selectedItem.name !== undefined &&
              selectedItem.name.substring(0, 95) +
                (selectedItem.name.length > 94 ? "…" : "")}
          </div>
          <div
            className={`${props.class}__arrow ${
              isShowing ? `${props.class}__arrow-up` : ""
            }`}
            onClick={toggle}
          />
        </div>
        <div
          className={`${props.class}__items  ${
            isShowing ? `change-height` : ``
          }`}
          style={{
            visibility: isShowing ? "visible" : "hidden",
            zIndex: props?.zIndex - 1
          }}
        >
          {Object.values(items).map(item => (
            <div
              key={item.id}
              onClick={() => selectItemHandler(item)}
              className={selectedItem === item ? "selected" : ""}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      {selectedItem && (
        <input type="hidden" name={props.name} value={selectedItem.id} />
      )}
    </>
  );
};

export default CustomSelect;
