import React from 'react'
import './ProfessionalPublicDiscussion.scss'

const InputField = ({
  type,
  label,
  value,
  placeholder,
  onChange,
  required = true,
}) => {
  return (
    <label className={'prof-discussion__label'}>
      {label}
      <input
        className={'prof-discussion__input'}
        required={required}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </label>
  )
}

export default InputField
