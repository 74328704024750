import React, {useContext, useEffect, useRef, useState} from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, useHistory } from 'react-router-dom';
import burger_menu from '../../../assets/images/svg/burger.svg';
import user_icon from '../../../assets/images/svg/user.svg';
import useModal from '../../../helpers/useModal';
import CoursesLogin from '../../CoursesLogin/CoursesLogin';
import {
  ConfirmModal,
  ErrorModal,
  LoginFormModal,
} from '../../Registration/Registration';
import {
  ConfirmModalDPO,
  ErrorModalDPO,
  RegistrationDPO,
} from '../../Registration/RegistrationDPO';
import Button, { LinkButton } from '../../UI/Button/Button';
import './Header.scss';
import { UserContext } from "../../App";

const Header = () => {
  const {userName, userRole} = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowRegistrationPopup, setIsShowRegistrationPopup] = useState(false);
  const [isShowingIframmeModal, setIsShowingIframmeModal] = useState(false);
  const popupRef = useRef(null);
  const popupRefRegistration = useRef(null);
  const history = useHistory();
  const {
    isShowingLoginForm,
    isShowingRegistrationForm,
    toggleCloseLogin,
    toggleOpenRegistration,
    toggleCloseRegistration,
    isShowingConfirmModal,
    toggleOpenConfirm,
    toggleCloseConfirm,
    toggleOpenLogin,
    toggleOpenError,
    toggleCloseError,
    isShowingErrorModal,
    toggleOpenRegistrationDPO,
    toggleCloseRegistrationDPO,
    isShowingRegistrationDPOForm,
    toggleOpenConfirmDPO,
    toggleOpenErrorDPO,
    toggleCloseErrorDPO,
    isShowingErrorDPO,
    toggleCloseConfirmDPO,
    isShowingConfirmDPO,
  } = useModal();

  function frameModalToggle() {
    setIsShowingIframmeModal(true);
    document.body.style.overflow = 'hidden';
  }

  function frameModalToggleBack() {
    setIsShowingIframmeModal(false);
    document.body.style.overflow = 'auto';
  }

  function linkLoad() {
    setIsShowingIframmeModal(true);
    document.body.style.overflow = 'hidden';
  }

  const showEnterMenu = () => {
    setIsShowPopup(!isShowPopup);
  };
  const showRegistrationMenu = () => {
    setIsShowRegistrationPopup(!isShowRegistrationPopup);
  };
  function handleClick(e) {
    if (popupRef && popupRef.current && popupRef.current.contains(e.target)) {
      return;
    }
    setIsShowPopup(false);
  }
  function handleClickForRegistration(e) {
    if (
      popupRefRegistration &&
      popupRefRegistration.current &&
      popupRefRegistration.current.contains(e.target)
    ) {
      return;
    }
    setIsShowRegistrationPopup(false);
  }

  let isMenuOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('mousedown', handleClickForRegistration);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('mousedown', handleClickForRegistration);
    };
  }, [popupRef, popupRefRegistration]);

  //useEffect(() => {
  //  history.location.hash === '#login' ? linkLoad() : frameModalToggleBack();
  //}, [history.location.hash]);

  return (
    <div className="Header">
      <div
        className="Header__logo"
        onClick={() => {
          history.push('/');
        }}
      />
      <div className="Header__navigation">
        <Link to="/about" className="about">
          <span>О проекте</span>
        </Link>
        <Link to="/bank?page=1&sortType=1" className="catalog">
          Реестр программ
        </Link>
        <Link to="/info" className="catalog">
          Информационные материалы
        </Link>
        <Link to="/seminar" className="catalog">
          Семинар
        </Link>
      </div>
      <div className="Header__login">
        {userName && userRole ? (
          <div className="exit-btn">
            {/* {LinkButton('enter-btn', 'Выход', 'logout')} */}
            <a href={`${process.env.REACT_APP_API_URL}/admin/admin-logout`}>
              <button className='enter-btn'>Выход</button>
            </a>
          </div>
        ) : (
          <>
            <div
              onClick={showRegistrationMenu}
              className="enterBtn"
              ref={popupRefRegistration}>
              <div>{Button('enter-btn  exit-btn-hide', 'Регистрация')}</div>
              <img className="user-icon" src={user_icon} alt="user-icon" />
              {isShowRegistrationPopup && <div className="popup-arror" />}
              {isShowRegistrationPopup && (
                <div className="enter-popup">
                  {<><div className="enter-popup__item">
                    <span onClick={toggleOpenRegistration}>
                      Автор программ ДПО
                    </span>
                  </div>
                    <div className="enter-popup__item">
                    <span onClick={toggleOpenRegistrationDPO}>
                      Организация, реализующая ДПП
                    </span>
                    </div></>}
                  {/*<div className="registration-closed">*/}
                  {/*  Уважаемые коллеги! В период с 1 июня 2021 года по 31 августа*/}
                  {/*  2021 года регистрация для авторов и учреждений ДПО на*/}
                  {/*  портале закрыта.*/}
                  {/*</div>*/}
                </div>
              )}
            </div>
          </>
        )}

        {userName && userRole ? (
          <div className="enter-admin-btn">
            <a href={`${process.env.REACT_APP_API_URL}/admin`}>
              <div className="user-name">{userName}</div>
              <div className="user-role">{userRole}</div>
            </a>
          </div>
        ) : (
          <div onClick={showEnterMenu} className="enterBtn" ref={popupRef}>
            <div>{Button('enter-btn  exit-btn-hide', 'Вход')}</div>
            <img className="user-icon" src={user_icon} alt="user-icon" />
            {isShowPopup && <div className="popup-arror" />}
            {isShowPopup && (
              <div className="enter-popup">
                {/*<div className="enter-popup__item">*/}
                {/*  <span onClick={frameModalToggle}>*/}
                {/*    Курсы повышения квалификации учителей*/}
                {/*  </span>*/}
                {/*</div>*/}

                {/*<div className="enter-popup__item">*/}
                {/*  <span onClick={toggleOpenLogin}>*/}
                {/*    Разработка программ дополнительного профессионального*/}
                {/*    образования*/}
                {/*  </span>*/}
                {/*</div>*/}

                <div className="enter-popup__item">
                    <a href={`${process.env.REACT_APP_API_URL}/admin`}>
                      <span style={{color: '#00148d' }}>Разработка программ дополнительного профессионального образования</span>
                    </a>
                </div>
              </div>
            )}
          </div>
        )}

        <img
          className="burger-menu"
          src={burger_menu}
          onClick={isMenuOpen}
          alt=""
        />
        <SlideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          userName={userName}
          userRole={userRole}
          toggleOpenRegistration={toggleOpenRegistration}
          toggleOpenRegistrationDPO={toggleOpenRegistrationDPO}
        />
      </div>

      <div>
        <RegistrationDPO
          toggleCloseRegistrationDPO={toggleCloseRegistrationDPO}
          isShowingRegistrationDPOForm={isShowingRegistrationDPOForm}
          toggleOpenConfirmDPO={toggleOpenConfirmDPO}
          toggleOpenErrorDPO={toggleOpenErrorDPO}
        />

        <LoginFormModal
          isShowingRegistrationForm={isShowingRegistrationForm}
          toggleCloseRegistration={toggleCloseRegistration}
          toggleOpenConfirm={toggleOpenConfirm}
          toggleOpenError={toggleOpenError}
        />
        <ConfirmModal
          isShowingConfirmModal={isShowingConfirmModal}
          toggleCloseConfirm={toggleCloseConfirm}
        />
        <ErrorModal
          toggleCloseError={toggleCloseError}
          isShowingErrorModal={isShowingErrorModal}
        />
        <CoursesLogin
          isShowingCoursesLoginForm={isShowingIframmeModal}
          toggleCloseCoursesLogin={frameModalToggleBack}
        />
        <ConfirmModalDPO
          toggleCloseConfirmDPO={toggleCloseConfirmDPO}
          isShowingConfirmDPO={isShowingConfirmDPO}
        />
        <ErrorModalDPO
          toggleCloseErrorDPO={toggleCloseErrorDPO}
          isShowingErrorDPO={isShowingErrorDPO}
        />
      </div>
    </div>
  );
};

export default Header;

const SlideMenu = (props) => {
  let registrationCloseMenu = () => {
    props.setIsOpen(false);
    props.toggleOpenRegistration();
  };
  let registrationDPOCloseMenu = () => {
    props.setIsOpen(false);
    props.toggleOpenRegistrationDPO();
  };

  return (
    <div className="slide-menu-wrapper">
      <Menu isOpen={props.isOpen} className="" right disableOverlayClick>
        <Link to="/about" className="">
          <span>О проекте</span>
        </Link>
        <Link to="/bank?page=1&sortType=1" className="">
          <span>Реестр программ</span>
        </Link>
        <Link to="/info" className="">
          <span>Информационные материалы</span>
        </Link>

        {props.userName && props.userRole ? (
          <div className="exit-btn">
            {LinkButton('enter-btn', 'Выход из учетной записи', 'logout')}
          </div>
        ) : (
          <div className="registration" onClick={registrationCloseMenu}>
            Регистрация автора
          </div>
        )}
        <div className="registration" onClick={registrationDPOCloseMenu}>
          РЕГИСТРАЦИЯ ОРГАНИЗАЦИЙ, РЕАЛИЗУЮЩИХ ДПП
        </div>
        <Link to="/seminar" className="no_m_b">
          <span>Семинар</span>
        </Link>
      </Menu>
    </div>
  );
};
