import React from 'react';
import { useHistory } from 'react-router-dom';
import Login from './Login';
import './Login.scss';

const LoginFormModal = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const history = useHistory();
  return (
    <Login
      isShowingLoginForm={isOpen}
      toggleCloseLogin={() => {
        history.goBack();
        setIsOpen(false);
      }}
    />
  );
};

export default LoginFormModal;
