import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import spinner from "../../assets/images/loader.gif";
import useFetch from "../../helpers/useFetch";
import Error from "../UI/Error/Error";
import PaginateWrapper from "../UI/PaginateWrapper/PaginateWrapper";
import "./../ArticlePage/ArticlePage.scss";
import "./MagazinePage.scss";

const MagazinePage = () => {
  const [articles, setArticles] = useState({});
  const [result, errors, getRequest] = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/journal/vektor`);

  useEffect(() => {
    getRequest(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    result && setArticles(result);
  }, [result]);

  const changePage = (data) => {
    getRequest(data.selected + 1);
  };

  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div
        className="AboutPage__container"
        style={{ marginBottom: "-30px", background: "#f5f5fe" }}
      >
        <div className="AboutPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="AboutPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <Link to="/seminar" className="AboutPage__breadcrumbs__main-link">
              Семинар
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <Link to="#" className="AboutPage__breadcrumbs__second-link">
              {" "}
              Журнал «Вектор образования»
            </Link>
          </div>
          <div />
        </div>
        <div className="AboutPage">
          <div />
          <div>
            <div className="AboutPage__title">Журнал «Вектор образования»</div>
            <div />
            <div className="AboutPage__content">
              {errors && <Error />}
              {articles && articles.items && articles.items.length ? (
                <>
                  <div className="Articles">
                    {articles.items.map((article, i) => {
                      return (
                        <div className="Article" key={i}>
                          <Link to={`/blog/${article.id}`}>
                            <div
                              className="Article__image"
                              style={{
                                background: `center / cover url(${article.preview_picture.previews.c_vector360x260}) no-repeat`,
                              }}
                            ></div>
                            <div className="Article__title">
                              {article.title}
                            </div>
                            <div className="Article__content">
                              {ReactHtmlParser(article.preview)}
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="spinner-wrapper">
                  <img src={spinner} alt="" />
                </div>
              )}
            </div>
          </div>
          <div />
        </div>
        {articles &&
          articles.pagination &&
          articles.pagination.total_pages &&
          articles.pagination.total_pages > 1 && (
            <PaginateWrapper changePage={changePage} items={articles} />
          )}
      </div>
    </>
  );
};

export default MagazinePage;
