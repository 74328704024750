import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import pdf from '../../assets/images/svg/pdf.svg';
import './SeminarPage.scss';

const youtube = [
  {
    id: 0,
    link: 'n9mDvQxbaOM',
    title: 'Запись семинара от',
    date: '21.07.2020',
  },
];

const YoutubeBlock = ({ isOpen, youtubeToggle, youtubeLink }) => {
  const width = window.innerWidth < 1520 ? '1303' : '1303';
  const height = window.innerWidth > 1520 ? '733' : window.innerHeight * 0.8;

  const opts = {
    height: height,
    width: width,
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="SeminarPage__content__media">
      {youtube.map((el) => (
        <p
          key={el.id}
          className="AboutPage__content__p cursor-pointer underline"
          onClick={() => youtubeToggle(el.link)}>
          {el.title} <b>{el.date}</b>
        </p>
      ))}
      {isOpen && (
        <div className="modal-overlay" onClick={youtubeToggle}>
          <div
            className="SeminarPage__content__media_window"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog">
            <YouTube videoId={youtubeLink} opts={opts} onReady={false} />
          </div>
        </div>
      )}
    </div>
  );
};

const Docs = () => (
  <div className="SeminarPage__content__docs">
    <p>Документы</p>
    <span className="SeminarPage__content__docs_link">
      Повестка семинара от <b>21.07.2020</b>
    </span>
    <a href={`${process.env.REACT_APP_API_URL}/uploads/ps210720.pdf`} download={true}>
      <img src={pdf} alt="" />
    </a>
  </div>
);

const SeminarPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState(null);

  const youtubeToggle = (link) => {
    setIsOpen(!isOpen);
    !isOpen ? setYoutubeLink(link) : setYoutubeLink(null);
  };

  return (
    <>
      <div className="Header__container">
        <div className="Header__background-image" />
        <div className="Header-container">
          <div className="main_title_container">
            <div />
          </div>
        </div>
      </div>
      <div className="AboutPage__container">
        <div className="AboutPage__breadcrumbs">
          <div />
          <div>
            <Link to="/" className="AboutPage__breadcrumbs__main-link">
              Главная
            </Link>
            <div className="AboutPage__breadcrumbs__slash" />
            <Link className="AboutPage__breadcrumbs__second-link">Семинар</Link>
          </div>
          <div />
        </div>
        <div className="AboutPage">
          <div />
          <div>
            <div
              className="AboutPage__content__title"
              style={{ marginBottom: '20px' }}>
              Семинар для представителей педагогических вузов и организаций
              дополнительного профессионального образования педагогов
              (работников образования)
            </div>
            <div className="AboutPage__title">
              «Вектор образования: вызовы, тренды, перспективы»
            </div>
            <div />
            <div className="AboutPage__content">
              <div>
                <p className="AboutPage__content__p">
                  Федеральное государственное автономное образовательное
                  учреждение дополнительного профессионального образования
                  «Центр реализации государственной образовательной политики и
                  информационных технологий» (ФГАОУ ДПО ЦРГОП и ИТ) приглашает
                  представителей педагогических вузов и организаций
                  дополнительного профессионального образования педагогов
                  (работников образования) принять участие в семинаре «Вектор
                  образования: вызовы, тренды, перспективы».
                </p>
              </div>
              <div className="AboutPage__content__list">
                <div className="AboutPage__content__title">Задачи семинара</div>
                <ul>
                  <li className="AboutPage__content__item">
                    интеграция возможностей участников семинара по реализации и
                    внедрению эффективных практик в области повышения
                    квалификации и предпрофессиональной подготовки педагогов
                  </li>
                  <li className="AboutPage__content__item">
                    создание единого научно-методического пространства
                    сопровождения педагогов
                  </li>
                  <li className="AboutPage__content__item">
                    создание условий для сетевого взаимодействия организаций
                    дополнительного профессионального образования педагогов
                    (работников образования)
                  </li>
                  <li className="AboutPage__content__item">
                    создание единой дискуссионной площадки и экспертного
                    сообщества в сфере дополнительного профессионального
                    образования.
                  </li>
                </ul>
              </div>
              <div className="SeminarPage__content__last-block">
                <div className="SeminarPage__content__last-block__item">
                  <span className="SeminarPage__content__last-block__item__first-row">
                    Форма проведения семинара:
                  </span>
                  <span className="SeminarPage__content__last-block__item__second-row">
                    дистанционная
                  </span>
                </div>
                <div className="SeminarPage__content__last-block__item">
                  <span className="SeminarPage__content__last-block__item__first-row">
                    Дата и время:
                  </span>
                  <span className="SeminarPage__content__last-block__item__second-row">
                    3 ноября 2020 года, 10:00 (МСК)
                  </span>
                </div>
                <div className="SeminarPage__content__last-block__item">
                  <span className="SeminarPage__content__last-block__item__first-row">
                    Периодичность:
                  </span>
                  <span className="SeminarPage__content__last-block__item__second-row">
                    раз в две недели по вторникам
                  </span>
                </div>
              </div>
              <div className="SeminarPage__content__link">
                <a
                  href="https://events.webinar.ru/12845733/6805799"
                  className="RegistryPage__check-btn">
                  ЗАРЕГИСТРИРОВАТЬСЯ НА СЕМИНАР
                </a>
              </div>
              <YoutubeBlock
                isOpen={isOpen}
                youtubeToggle={youtubeToggle}
                youtubeLink={youtubeLink}
              />
              <Docs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeminarPage;
