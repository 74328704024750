export default function validate(values) {
  let errors = {};
  console.log(values.phone)
  if (!values.email) {
    errors.email = "Электронная почта обязательное поле";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Адрес электронной почты не корректный";
  }

  if (!values.lastName) {
    errors.lastName = "Фамилия обязательное поле";
  }

  if (!values.firstName) {
    errors.firstName = "Имя обязательное поле";
  }

  if (!values.patronymic) {
    errors.patronymic = "Отчество обязательное поле";
  }

  if (!values.region || values.region === "0") {
    errors.region = "Регион обязательное поле";
  }

  if (!values.city) {
    errors.city = "город обязательное поле";
  }

  if (!values.organization) {
    errors.organization = "Организация обязательное поле";
  }

  if (!values.position) {
    errors.position = "Должность обязательное поле";
  }

  if (!values.phone) {
    errors.phone = "Номер телефона обязательное поле";
  } else if (
      !/^^((\+7|7|8)+([0-9]){10})$/.test(
      values.phone
    )
  ) {
    errors.phone = "телефон не корректный";
  }

  return errors;
}
