import React from 'react';
import { Link } from 'react-router-dom';
import img_403 from '../../assets/images/png/403.png';
import img_404 from '../../assets/images/png/404.png';
import img_500 from '../../assets/images/png/500.png';
import logo from '../../assets/images/png/logo.png';
import './ErrorPage.scss';

export const Page404 = () => (
  <>
    <div className="Header__container">
      <div className="Header__background-image" />
      <div className="Header-container">
        <div className="main_title_container">
          <div />
        </div>
      </div>
    </div>
    <div className="ErrorPage__container">
      <div className="ErrorPage__breadcrumbs">
        <div />
        <div>
          <Link to="/" className="ErrorPage__breadcrumbs__main-link">
            Главная
          </Link>
          <div className="ErrorPage__breadcrumbs__slash" />
          <Link className="ErrorPage__breadcrumbs__second-link">404</Link>
        </div>
        <div />
      </div>
      <div className="ErrorPage">
        <div />
        <div className="ErrorPage__content">
          <img src={img_404} alt="" />
          <div>Страница не найдена</div>
        </div>
        <div />
      </div>
    </div>
  </>
);

export const Page500 = () => (
  <>
    <div className="Header__container">
      <div className="Header__background-image" />
      <div className="Header-container">
        <div className="main_title_container">
          <div />
        </div>
      </div>
    </div>
    <div className="ErrorPage__container">
      <div className="ErrorPage__breadcrumbs">
        <div />
        <div>
          <Link to="/" className="ErrorPage__breadcrumbs__main-link">
            Главная
          </Link>
          <div className="ErrorPage__breadcrumbs__slash" />
          <Link className="ErrorPage__breadcrumbs__second-link">500</Link>
        </div>
        <div />
      </div>
      <div className="ErrorPage">
        <div />
        <div className="ErrorPage__content">
          <img src={img_500} alt="" />
          <div>внутренняя ошибка сервера</div>
        </div>
        <div />
      </div>
    </div>
  </>
);

export const Page403 = () => (
  <>
    <div className="Header__container">
      <div className="Header__background-image" />
      <div className="Header-container">
        <div className="main_title_container">
          <div />
        </div>
      </div>
    </div>
    <div className="ErrorPage__container">
      <div className="ErrorPage__breadcrumbs">
        <div />
        <div>
          <Link to="/" className="ErrorPage__breadcrumbs__main-link">
            Главная
          </Link>
          <div className="ErrorPage__breadcrumbs__slash" />
          <Link className="ErrorPage__breadcrumbs__second-link">403</Link>
        </div>
        <div />
      </div>
      <div className="ErrorPage">
        <div />
        <div className="ErrorPage__content">
          <img src={img_403} alt="" />
          <div>ДОСТУП ЗАПРЕЩЕН</div>
        </div>
        <div />
      </div>
    </div>
  </>
);
