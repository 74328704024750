import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './HomePage.scss';
import { News } from './News/News';

const HomePage = () => {
  const history = useHistory();
  const program = useRef(null);

  const scrollTo = () => {
    history.push('#program');
    window.scrollTo({
      top: program.current.offsetTop + 100,
      behavior: 'smooth', // smooth scroll.
    });
  };

  useEffect(() => {
    if (history.location.hash === '#program') {
      scrollTo();
    }
  }, [history.location.hash, scrollTo]);
  return (
    <>
      <div className="Header__container">
        <div className="Header-container">
          <div className="main_title_container"></div>
        </div>
      </div>
      <News />
    </>
  );
};

export default HomePage;
