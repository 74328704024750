import React from 'react';
import ReactPaginate from 'react-paginate';
import './PaginateWrapper.scss'

const PaginateWrapper = ({changePage, items}) => {

    const onPageChange = (data) => {
        const chosenPage = data.selected + 1
        if (items.pagination.current_page !== chosenPage) {
            changePage(data);
        }
    }

    return (
        <>
            {
                <div className="Pagination-wrapper">
                    <ReactPaginate
                        pageCount={items.pagination.total_pages}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        onPageChange={onPageChange}
                        initialPage={0}
                        nextLabel='следующая'
                        previousLabel='предыдущая'
                    />
                </div>
            }
        </>
    )
}

export default PaginateWrapper;
