export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = "Логин обязательное поле";
  }

  if (!values.password) {
    errors.password = "Пароль обязательное поле";
  }
  return errors;
}
