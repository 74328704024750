import React from 'react';
import { Link } from 'react-router-dom';
import term_page_1 from '../../assets/images/jpg/Пользовательское_соглашение-1.jpg';
import term_page_2 from '../../assets/images/jpg/Пользовательское_соглашение-2.jpg';
import term_page_3 from '../../assets/images/jpg/Пользовательское_соглашение-3.jpg';
import term_page_4 from '../../assets/images/jpg/Пользовательское_соглашение-4.jpg';
import term_page_5 from '../../assets/images/jpg/Пользовательское_соглашение-5.jpg';
import logo from '../../assets/images/png/logo.png';
import './TermsOfUse.scss';

const TermsOfUse = () => (
  <>
    <div className="Header__container">
      <div className="Header__background-image" />
      <div className="Header-container">
        <div className="main_title_container">
          <div />
        </div>
      </div>
    </div>
    <div className="InformationPage__container">
      <div className="InformationPage__breadcrumbs">
        <div />
        <div>
          <Link to="/" className="InformationPage__breadcrumbs__main-link">
            Главная
          </Link>
          <div className="InformationPage__breadcrumbs__slash" />
          <Link className="InformationPage__breadcrumbs__second-link">
            Пользовательское соглашение
          </Link>
        </div>
        <div />
      </div>
      <div className="InformationPage">
        <div />
        <div>
          <div className="images-wrapper">
            {data.map((item) => (
              <img className="terms-images" src={item} alt="" />
            ))}
          </div>
        </div>
        <div />
      </div>
    </div>
  </>
);

export default TermsOfUse;

const data = [term_page_1, term_page_2, term_page_3, term_page_4, term_page_5];
