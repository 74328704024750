import React, { useState } from 'react'
import { Form, Checkbox, Input } from 'semantic-ui-react'

const CheckboxGroup =(props)=> {
    const [values, setStateValues] = useState([]);
    const [input_value, setInputValue] = useState('');
    const [custom_value, setCustomValue] = useState(false);

    function setValue(checked, value, custom_input_value=false){        
        if(custom_input_value) setInputValue(custom_input_value);
        if(value==='other' && custom_input_value!==false){
            setCustomValue(checked ? custom_input_value : false);
            props.setValue(checked ? values.concat([custom_input_value]) : values);
        }else{
            if(!checked){
                if(values.includes(value)){
                    var index = values.indexOf(value);
                    values.splice(index, 1);
                }
            }else{
                if(!values.includes(value)) values.push(value);
            }
            const new_values = custom_value ? values.concat([custom_value]) : values;
            props.setValue(new_values.slice(0));
        }
    }

    return (
        <React.Fragment>
            {props.fields.map(name=>{
                return(
                    <div key={name}><Checkbox onChange={(e,data)=>setValue(data.checked,data.label)} label={name} /><br /></div>
                )
            })}
            <Checkbox style={{marginRight:'20px'}} onChange={(e,data)=>{setCustomValue(true);setValue(data.checked, 'other', input_value)}} label={props.label ? props.label : 'иные (какие именно)'} />
            <Input disabled={custom_value===false} onChange={(e)=>setValue(true,'other', e.target.value)} />              
        </React.Fragment>
    )
  
}
export default CheckboxGroup