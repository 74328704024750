import React from "react";

import doc from "../assets/images/svg/doc-new.svg";
import pdf from "../assets/images/svg/pdf.svg";
import zip from "../assets/images/svg/zip.svg";

export default function checkFile(file) {
  let downloadFile = file.filename.split(".").pop();

  const checkFileExtention = (extention, fileIcon) => {
    return downloadFile === extention ? (
      <img
        className="BankCard__pdf__link custom-pdf-link"
        src={fileIcon}
        alt=""
      />
    ) : (
      ""
    );
  };

  switch (downloadFile) {
    case "pdf":
      return checkFileExtention("pdf", pdf);
    case "PDF":
      return checkFileExtention("PDF", pdf);
    case "doc":
      return checkFileExtention("doc", doc);
    case "DOC":
      return checkFileExtention("DOC", doc);
    case "docx":
      return checkFileExtention("docx", doc);
    case "DOCX":
      return checkFileExtention("DOCX", doc);
    case "zip":
      return checkFileExtention("zip", zip);
    case "ZIP":
      return checkFileExtention("ZIP", zip);
    case "gzip":
      return checkFileExtention("gzip", zip);
    case "GZIP":
      return checkFileExtention("GZIP", zip);
    default:
      return;
  }
}
