import axios from "axios";
import moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import spinner from "../../../assets/images/loader.gif";
import "./FullProgrammPage.scss";

const FullProgrammPage = (props) => {
  const [cardData, setCardData] = useState(null);

  useEffect(() => {
    async function fetchRegions() {
      const { id } = props.match.params;
      const result = await axios(`${process.env.REACT_APP_API_URL}/api/v1/programs/${id}`);
      setCardData(result.data);
    }
    fetchRegions();
  }, [props.match.params]);

  return (
    <div>
      <div className="FullProgramm__container">
        {cardData === null ? (
          <div
            className="spinner-wrapper"
            style={{
              height: "300px",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <img src={spinner} alt="" />
          </div>
        ) : null}
        {cardData && (
          <div className="fade-animation">
            <div className="AboutPage__breadcrumbs">
              <div />
              <div className="bc-width">
                <Link to="/" className="FullProgramm__breadcrumbs__main-link">
                  Главная
                </Link>
                <div className="FullProgramm__breadcrumbs__slash" />
                <Link
                  to={`/registry/?page=1`}
                  className="FullProgramm__breadcrumbs__main-link"
                >
                  Реестр программ
                </Link>
                <div className="FullProgramm__breadcrumbs__slash" />
                <div className="FullProgramm__breadcrumbs__second-link">
                  {cardData && cardData.name}
                </div>
              </div>
              <div />
            </div>
            <div className="FullProgramm">
              <div />
              {cardData && (
                <div>
                  <div className="FullProgramm__title">{cardData.name}</div>
                  <div></div>
                  <div className="FullProgramm__content">
                    <div className="FullProgramm__content__item-wrapper padding border-top">
                      <div className="FullProgramm__content__item-category">
                        дополнительная профессиональня программа
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.additional_professional_program.name}
                      </div>
                    </div>
                    {/* <div className="FullProgramm__content__item-wrapper">
                <div className="FullProgramm__content__item-category">
                  Тип программы
                </div>
                <div className="FullProgramm__content__item-data">Да</div>
              </div> */}
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        Автор
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.author.surname} {cardData.author.name}{" "}
                        {cardData.author.patronymic}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        категория слушателей
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.students_category}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        Форма обучения
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.education_form.name}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        срок обучения
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.course_of_training} часов
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        дата разработки программы в субъекте РФ
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.published_at &&
                          moment(cardData.published_at).format("D MMMM YYYY")}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        дата размещения программы в реестре
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {moment(
                          cardData.expertise_programs
                            .map((item) => item.date_end)
                            .sort()
                            .reverse()[0]
                        ).format("D MMMM YYYY")}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        организация
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.author.organization.name}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        сайт организации
                      </div>
                      <div className="FullProgramm__content__item-data">
                        <a
                          class="link"
                          href={cardData.author.organization.site}
                          target="_blank"
                        >
                          {cardData.author.organization.site}
                        </a>
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        субъект рф
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {console.log(cardData.author.region)}
                        {cardData.author.region.name}
                      </div>
                    </div>
                    <div className="FullProgramm__content__item-wrapper">
                      <div className="FullProgramm__content__item-category">
                        Описание содержания
                      </div>
                      <div className="FullProgramm__content__item-data">
                        {cardData.goal}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(FullProgrammPage);
